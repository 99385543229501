<template>

  <div>
    <div class="w-100 px-3 pt-2 pb-5  d-flex flex-column ">

      <div class="row ">
        <div class="col-12">
          <h1>Клиенты</h1>

          <!-- поиск и фильтр -->
          <div class="row">
            <div class="col-3">
              <v-text-field label="Быстрый поиск" @keydown.enter="getList" @blur="getList"
                            v-model="filterAndSort.search"></v-text-field>
            </div>

            <div class="col-3">
              <v-select
                  label="Тип клиента"
                  v-model="filterAndSort.clientType"
                  item-text="label"
                  item-value="id"
                  :items="clientTypeList"

              ></v-select>
            </div>


            <div class="col-3">
              <v-select
                  label="В архиве"
                  v-model="filterAndSort.inArchive"
                  item-text="label"
                  item-value="id"
                  :items="yesNoList"
              ></v-select>
            </div>

            <div class="col-3">
              <v-select
                  label="Сортировка по:"
                  v-model="filterAndSort.orderBy"
                  item-text="label"
                  item-value="id"
                  :items="sortList"
              ></v-select>
            </div>
          </div>


          <!-- записей к показу -->
          <div class="">
            <span class="small">Отображается клиентов:  {{ clientsList.length }} из {{ clientCount }}</span>
            <a href="#" @click="resetFilterAndSort" class="small ms-2 d-inline-block text-warning">(Сбросить
              фильтры)</a>
          </div>


          <v-progress-linear
              indeterminate
              :active="ajaxLoad"
              class="mt-3"
              color="yellow darken-2"
          ></v-progress-linear>


        </div>
      </div>

      <!-- список клиентов -->
      <div class=" spisok-clientov row">
        <template v-if="clientsList.length > 0">
          <div class="col-3" v-for="(client, index)  in clientsList" :key="index">
            <v-card
                outlined
                elevation="7"
                class="shadow"
            >
              <v-list-item three-line>
                <v-list-item-content>

                  <div class="text-overline mb-3 " :class="{
                      'text-primary':client.clientType === 1,
                      'text-info':client.clientType === 2,
                      'text-success':client.clientType === 3,
                      'text-warning':client.clientType === 4,
                    }">
                    {{ client.clientTypeDisplay }}
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    <b>{{ client.name }}</b>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="client.inn">ИНН: {{ client.inn }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="client.fioResponsible">ФИО ответственного:
                    {{ client.fioResponsible }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="client.phoneResponsible">Телефон ответственного:
                    {{ client.phoneResponsible }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="client.emailResponsible">Email ответственного:
                    {{ client.emailResponsible }}
                  </v-list-item-subtitle>
                  <hr class="my-2">
                  <v-list-item-subtitle>Доход за всё время: {{ client.budgetForAllTime }} руб.
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>РП за всё время: {{ client.countAdvertisingProgramForAllTime }} шт.
                  </v-list-item-subtitle>


                </v-list-item-content>

                <template>
                  <v-list-item-avatar
                      tile
                      class="rounded-circle  shadow"
                      :class="{
                                          'bg-primary bg-gradient text-white':client.clientType === 1,
                                          'bg-info  bg-gradient text-white':client.clientType === 2,
                                          'bg-success bg-gradient text-white':client.clientType === 3,
                                          'bg-warning bg-gradient text-white':client.clientType === 4,
                                        }"
                      size="80"
                  ><span class="h4 p-0 m-0 "><b>{{ client.name | short }}</b></span>
                  </v-list-item-avatar>
                </template>
              </v-list-item>


              <v-card-actions>
                <div class="d-flex justify-content-between w-100">
                  <div>
                    <v-btn
                        color="#000"
                        text
                        @click="showDialog(client.id)"
                    >
                      Подробнее
                    </v-btn>
                  </div>
                  <div>
                    <v-btn
                        color="#DC3545"
                        text
                        v-if="!client.inArchive"
                        @click="archiveClient(client.id)"
                    >
                      В архив
                    </v-btn>

                    <v-btn
                        color="#3599dc"
                        text
                        v-else
                        @click="archiveClient(client.id)"
                    >
                      Вернуть из архива
                    </v-btn>
                  </div>
                </div>
              </v-card-actions>
            </v-card>

          </div>
        </template>
        <v-alert
            v-else
            dense
            icon="mdi-information-outline"
            text
            color="orange"
        ><span>Поиск не дал результата или сервер не отвечает={ Уточните пожалуйста параметры поиска.</span>
        </v-alert>
      </div>

      <!-- модалка -->

      <app-client-window
          ref="clientWindow"
          @closeDialog="closeDialog($event)"
          @showAlert="$emit('showAlert', $event)"
      />

    </div>

    <v-btn
        class="mx-2 orange btn-add"
        fab
        @click="showDialog()"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>

  </div>

</template>

<script>
import AppClientWindow from "@/components/AppClientWindow";
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";


export default {
  name: 'ClientiView',

  components: {
    AppClientWindow
  },

  data() {
    return {
      ajaxLoad: false,
      clientsList: [],
      dialogShow: false,
      curentclient: {},
      clientCount: 0,

      filterAndSort: {
        orderBy: 'name',
        search: '',
        clientType: '',
        inArchive: false
      },

      clientTypeList: [{id: '', label: '-'}, ...LOCAL_CONFIG.clientTypeList],

      sortList: [
        {id: 'name', label: 'Наименования от А до Я'},
        {id: '-name', label: 'Наименования от Я до А'},
        // {id: 'dohod_za_vse_vremia', label: 'Доход по возрастанию'},
        // {id: '-dohod_za_vse_vremia', label: 'Доход по убыванию'},
      ],

      yesNoList: LOCAL_CONFIG.yesNoList

    }
  },

  methods: {
    showDialog(id = null) {
      this.$refs.clientWindow.openDialog(id);
    },

    async archiveClient(id) {
      this.ajaxLoad = true;
      const url = `${LOCAL_CONFIG.urls.client}${id}/`;
      await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': CookieHelper.getCookie('csrftoken')
        },
      });
      this.getList()
      this.ajaxLoad = false;
    },

    closeDialog() {
      this.getList();
    },

    resetFilterAndSort() {
      this.filterAndSort = {
        orderBy: 'name',
        search: '',
        clientType: '',
        inArchive: false
      };
      this.getList();
    },

    async getList() {
      this.ajaxLoad = true;
      const url = `${LOCAL_CONFIG.urls.client}?` + new URLSearchParams(this.filterAndSort);
      const response = await fetch(url);
      const data = await response.json();
      this.clientsList = data['data'];
      this.clientCount = data['count'];
      this.ajaxLoad = false;

    }
  },

  async created() {
    await this.getList();
    this.$emit('loadComplete',);
  },


  watch: {
    'filterAndSort.orderBy'() {
      this.getList();
    },
    'filterAndSort.clientType'() {
      this.getList();
    },
    'filterAndSort.inArchive'() {
      this.getList();
    },
  },


  filters: {
    //обрезка название клиента до 2 первых символов
    short: function (value) {
      if (value.length >= 2) {
        return value[0] + value[1]
      } else if (value.length === 1) {
        return value[0]
      }
      return value

    }
  },
}
</script>

<style lang="scss" scoped>

.avatarka {
  width: 80px;
  align-self: start;
  margin-top: 10px;
}


.btn-add {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #fff !important;
}
</style>
