<template>
  <v-card class="w-100 d-flex justify-content-center loader border-radius-0" dark>
    <div class="align-self-center text-center">
      <img class="fadeIn" width="125" src="../assets/logo2.png" alt="">
      <p class="text-white h3 mt-3"><b>Загрузка...</b></p>
      <p class="text-white h3 mt-4 small"><b>обычно не дольше 10 секунд =}</b></p>
      <p class="text-white h3 mt-1 small"><b>если дольше, то обновите страничку=}</b></p>
      <p class="text-white h3 mt-1 small"><b>если не помогло, то звоните 8-924-375-05-73 =}</b></p>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "AppLoader"
}
</script>

<style scoped>
.loader{
  height: 100vh;
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration:2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>