<template>

  <div class="w-100 px-3 pt-2  d-flex flex-column mb-5">

    <h1>Свободные площадки</h1>

    <!-- сортировки и фильтры -->
    <div class="row">


      <div class="col-12 col-md-5 py-0 py-md-3 my-0 my-md-0">
        <v-select
            label="Сортировка по:"
            v-model="filterAndSort.orderBy"
            item-text="label"
            item-value="id"
            :items="orderByList"
        ></v-select>
      </div>

      <div class="col-12 col-md-5 py-0 py-md-3 my-0 my-md-0">

        <v-select
            label="Период"
            v-model="filterAndSort.period"
            item-text="name"
            item-value="slug"
            :items="periodList"
        ></v-select>
      </div>

      <div class="col-12 col-md-2 py-0 py-md-3 mt-0 mb-3 my-md-0 text-md-center text-left">
        <v-btn color="orange" dark :href="linkReportFreeElevators" target="_blank">
          <v-icon>mdi-file-excel</v-icon>
          <b>Скачать в Excel </b></v-btn>
      </div>

    </div>

    <!-- записей к показу -->
    <div class="mb-0 pb-0 col-12">
      <span class="small">Отображается:  {{ countHouseServer }} домов | {{ countElevatorServer }} лифтов </span>
    </div>

    <!-- ajax лоадер -->
    <v-progress-linear
        indeterminate
        :active="ajaxLoad"
        class="mt-3"
        color="yellow darken-2"
    ></v-progress-linear>

    <!-- таблица данных -->
    <template>
      <v-simple-table
          fixed-header
          height="700px"
          v-if="houseList.length > 0"
      >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Адрес
            </th>
            <th class="text-center">
              Лифтов в доме
            </th>
            <th class="text-center">
              Свободных площадок в лифте
            </th>

          </tr>
          </thead>
          <tbody class="small">
          <tr
              v-for="item in houseList"
              :key="item.address"
          >
            <td>{{ item.address }}</td>
            <td class="text-center">{{ item.elevatorCount }}</td>
            <td class="text-center">{{ item.countFreeArea }}</td>

          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-alert
          v-else
          dense
          icon="mdi-information-outline"
          text
          color="orange"
      ><span>Свободных площадок в данном периоде нет. Посмотрите пожалуйста следующий</span>
      </v-alert>
    </template>

  </div>


</template>


<script>

import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";

export default {
  name: "FreeElevatorsView",
  components: {},

  data() {
    return {
      houseList: [],
      countHouseServer: 0,
      countElevatorServer: 0,
      ajaxLoad: false,

      filterAndSort: {
        period: '',
        orderBy: '-order_index'
      },
      periodList: [],
      orderByList: [
        {id: 'address', label: 'Наименования от А до Я'},
        {id: '-address', label: 'Наименования от Я до А'},
        {id: '-order_index', label: 'Согласно КП'},
      ],
    }
  },

  computed: {
    linkReportFreeElevators: function () {
      return `${LOCAL_CONFIG.urls.renderReportFreeArea}${this.filterAndSort.period}`
    }
  },

  methods: {
    async getList() {
      this.ajaxLoad = true;
      const url = `${LOCAL_CONFIG.urls.reportFreeElevators}?` + new URLSearchParams(this.filterAndSort);
      const response = await fetch(url, {method: 'get'});
      const data = await response.json();
      this.houseList = data.data;
      this.countHouseServer = data['countHouse'];
      this.countElevatorServer = data['elevatorCount'];
      this.ajaxLoad = false;
    },
    async getPeriodList() {
      const url = `${LOCAL_CONFIG.urls.period}` ;
      const response = await fetch(url, {method: 'get'});
      const data = await response.json();
      this.periodList = data.periodList;
      this.filterAndSort.period = data.periodNow;
    },
  },

  async created() {
    await this.getPeriodList();
    // await this.getList();
    this.$emit('loadComplete',);
  },

  watch: {
    'filterAndSort.orderBy'() {
      this.getList();
    },
    'filterAndSort.period'() {
      this.getList();
    },
  },


}
</script>

<style scoped>

</style>