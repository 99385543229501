<template>
  <div :class="{'wrap':dialogShow}">
    <v-dialog
        :value="dialogShow"
        persistent
        scrollable
        max-width="1200px"
    >
      <v-card>
        <v-card-title class="text-center bg-light ">
          <div class="h3 " v-if="house.id">{{ house.address }}</div>
          <div class="h3 " v-else>Новый дом</div>

          <div class="ms-auto">
            <button class="close-btn" @click="closeDialog()">
              <v-icon>
                mdi-close-circle
              </v-icon>
            </button>
          </div>
        </v-card-title>

        <v-card-text>
          <div class="row">

            <div class="col-12 mb-3">
              <v-text-field label="Адрес*"
                            v-model="house.address"></v-text-field>
            </div>

            <div class="col-6">
              <v-text-field label="Количество лифтов*"
                            v-model="house.elevatorCount"></v-text-field>
            </div>

            <div class="col-6">
              <v-select
                  label="Тип лифтборда*"
                  v-model="house.liftboardLayout"
                  item-text="name"
                  item-value="id"
                  :items="liftboardLayoutList"
              ></v-select>
            </div>

            <div class="col-6">

              <v-text-field label="Индекс сортировки(больше-раньше)" type="number"
                            v-model="house.orderIndex"></v-text-field>
            </div>

               <div class="col-6">
              <v-select
                  label="Расклейщик"
                  v-model="house.placemarker"
                  item-text="fio"
                  item-value="id"
                  :items="placemarkerList"
              ></v-select>
            </div>


          </div>

          <!-- кнопки управления -->
          <div class="row">
            <div class="col-6">
              <v-btn
                  color="warning"
                  dark
                  @click="saveHouse()"
              >
                <v-icon>
                  mdi-content-save
                </v-icon>
                Сохранить
              </v-btn>
            </div>
            <div class="col-6 text-right" v-if="house.id">
              <v-btn
                  color="#DC3545"
                  text
                  v-if="!house.inArchive"
                  @click="archiveHouse(house.id)"
              >
                В архив
              </v-btn>

              <v-btn
                  color="#3599dc"
                  text
                  v-else
                  @click="archiveHouse(house.id)"
              >
                Вернуть из архива
              </v-btn>
            </div>
          </div>
        </v-card-text>


      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "AppHouseWindow",

  data() {
    return {
      house: {
        id: null,
        address: '',
        elevatorCount: 1,
        liftboardLayout: 1,
        orderIndex: 0,
        placemarker: null,
        inArchive: false,
      },
      dialogShow: false,
      liftboardLayoutList: [],
      placemarkerList: [],
    }
  },

  methods: {

    resetHouse() {
      this.house = {
        id: null,
        address: '',
        placemarker: null,
        elevatorCount: 1,
        liftboardLayout: 1,
        orderIndex: 0,
        inArchive: false,
      }
    },

    validateSaveData() {
      const is1 = this.house.address !== undefined && this.house.address.length > 0;
      const is2 = this.house.elevatorCount !== undefined && this.house.elevatorCount > 0;
      const is3 = this.house.liftboardLayout !== undefined;
      return is1 && is2 && is3;
    },

    async archiveHouse(id) {
      try {
        const url = `${LOCAL_CONFIG.urls.house}${id}/`;
        const r = await fetch(url, {
          method: 'delete',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        if (r.status === 200 || r.status === 201) {
          this.$emit('showAlert', 'Данные сохранены');
          this.$emit('closeDialog',);
          this.closeDialog();
        } else {
          this.$emit('showAlert', await r.text());
        }
      } catch (error) {
        this.$emit('showAlert', 'Ошибка при обращение к серверу');
      }
    },

    async getHouse(id) {
      const url = `${LOCAL_CONFIG.urls.house}${id}/`;
      const r = await fetch(url);
      this.house = await r.json();
    },

    async getLiftboardLayout() {
      const url = `${LOCAL_CONFIG.urls.liftboardLayout}`;
      const r = await fetch(url);
      this.liftboardLayoutList = await r.json();
    },

    async getPlacemarkerList() {
      const url = `${LOCAL_CONFIG.urls.placemarker}`;
      const r = await fetch(url);
      this.placemarkerList = await r.json();
    },

    async saveHouse() {
      const tmp = this.house.id ? `${this.house.id}/` : "";
      const method = this.house.id ? 'PUT' : "POST";
      if (!this.validateSaveData()) {
        this.$emit('showAlert', 'Не все поля заполнены');
        return false;
      }

      const url = `${LOCAL_CONFIG.urls.house}${tmp}`;

      try {
        const r = await fetch(url, {
          method: method,
          body: JSON.stringify(this.house),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });

        if (r.status === 200 || r.status === 201) {
          this.$emit('showAlert', 'Данные сохранены');
          this.$emit('closeDialog',);
          this.closeDialog();
        } else {
          this.$emit('showAlert', await r.text());
        }
      } catch (error) {
        this.$emit('showAlert', 'Ошибка при обращение к серверу');
      }

    },

    closeDialog: function () {
      this.dialogShow = false;
      this.resetHouse();
      this.$emit('closeDialog', {});
    },

    async openDialog(houseId) {
      await this.getLiftboardLayout();
      await this.getPlacemarkerList();
      if (houseId) {
        await this.getHouse(houseId)
      } else {
        this.resetHouse();
      }
      this.dialogShow = true;
    },

  },


}
</script>

<style scoped>
.v-dialog__container {
  display: block;

}

.required label::after {
  content: "*";
}

.close-btn:hover i {
  color: #ffa900;
}

.wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.v-dialog__content--active {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>