<template>
  <div>
    <div class="w-100 px-3 pt-2  d-flex flex-column mb-5 ap-view">

      <div class="row ">
        <div class="col-12">
          <h1>Реклама</h1>

          <!-- поиск и фильтр -->
          <div class="row">

            <div class="col-2">
              <v-text-field label="Поиск по адресу" @keydown.enter="getList" @blur="getList"
                            v-model="filterAndSort.searchAddress"></v-text-field>
            </div>

            <div class="col-2">
              <v-text-field label="Поиск по клиенту" @keydown.enter="getList" @blur="getList"
                            v-model="filterAndSort.searchClient"></v-text-field>
            </div>


            <div class="col-2">
              <v-select
                  label="Занятость и конфликты"
                  :items="bysyAndConflictList"
                  item-text="label"
                  item-value="id"
                  v-model="filterAndSort.bysyAndConflict"
              ></v-select>
            </div>

            <div class="col-2">
              <v-select
                  label="Достаточность фото"
                  :items="hasPhotoList"
                  item-text="label"
                  item-value="id"
                  v-model="filterAndSort.hasPhoto"
              ></v-select>
            </div>


            <div class="col-2">
              <v-select
                  label="Сортировка по:"
                  v-model="filterAndSort.orderBy"
                  item-text="label"
                  item-value="id"
                  :items="orderByList"
              ></v-select>
            </div>

            <div class="col-2">

              <v-select
                  label="Период"
                  v-model="filterAndSort.period"
                  item-text="name"
                  item-value="slug"
                  :items="periodList"
              ></v-select>
            </div>
          </div>

          <!-- записей к показу -->
          <div class="">
                <span class="small">Отображается:  {{ countHouseAfterFilter }} домов ({{ countElevatorAfterFilter }} лифтов) из {{
                    countHouseAll
                  }} домов ({{ countElevatorAll }} лифтов)</span>
            <a href="#" @click="resetFilterAndSort" class="small ms-2 d-inline-block text-warning">(Сбросить
              фильтры)</a>
          </div>

          <div class="row">
            <div v-if="statistic" class="col-12">
              <v-chip
                  small
                  class="ma-2 shadow"
                  color="white"
              >
                Всего: {{ statistic.areaCount }} шт.
              </v-chip>
              <v-chip
                  small
                  class="ma-2 shadow mr-5"
                  color="white"
              >
                Свободных: {{ statistic.freeCount }} шт. ({{ statistic.freePercent }}%)
              </v-chip>


              <v-chip
                  small
                  class="ma-2 shadow"
                  color="primary"
                  :outlined="filterAndSort.clientType!==1"
                  @click="setFilterClientType(1)"
              >
                <b>Рекламных: {{ statistic.advertiserCount }} шт. ({{ statistic.advertiserPercent }}%)</b>
              </v-chip>
              <v-chip
                  small
                  class="ma-2 text-white shadow"
                  color="green"
                  :outlined="filterAndSort.clientType!==3"
                  @click="setFilterClientType(3)"
              >
                <b>Партнеры: {{ statistic.partnerCount }} шт. ({{ statistic.partnerPercent }}%)</b>
              </v-chip>
              <v-chip
                  small
                  class="ma-2 shadow"
                  color="info"
                  :outlined="filterAndSort.clientType!==2"
                  @click="setFilterClientType(2)"
              >
                <b>ТСЖ/УК: {{ statistic.tszUkCount }} шт. ({{ statistic.tszUkPercent }}%)</b>
              </v-chip>
              <v-chip
                  small
                  class="ma-2 text-white shadow"
                  color="orange"
                  :outlined="filterAndSort.clientType!==4"
                  @click="setFilterClientType(4)"
              >
                <b>Кенгуру: {{ statistic.kenguruCount }} шт. ({{ statistic.kenguruPercent }}%)</b>
              </v-chip>


              <v-chip
                  small
                  class="ma-2 text-white shadow ml-5"
                  color="purple"
                  :outlined="filterAndSort.statusAdvertisingProgram!==1"
                  @click="setFilterStatusAdvertisingProgram(1)"
              >
                <b>Новые: {{ statistic.newCount }} шт. ({{ statistic.newPercent }}%)</b>
              </v-chip>

              <v-chip
                  small
                  class="ma-2 text-white shadow"
                  color="pink"
                  :outlined="filterAndSort.statusAdvertisingProgram!==2"
                  @click="setFilterStatusAdvertisingProgram(2)"
              >
                <b>Продление: {{ statistic.prolongCount }} шт. ({{ statistic.prolongPercent }}%)</b>
              </v-chip>

              <v-chip
                  small
                  class="ma-2 text-white shadow"
                  color="deep-purple"
                  :outlined="filterAndSort.statusAdvertisingProgram!==3"
                  @click="setFilterStatusAdvertisingProgram(3)"
              >
                <b>Замены: {{ statistic.changingLayoutCount }} шт. ({{ statistic.changingLayoutPercent }}%)</b>
              </v-chip>
            </div>
          </div>


          <div class="">
            <v-progress-linear
                indeterminate
                :active="ajaxLoad"
                class="mt-3"
                color="yellow darken-2"
            ></v-progress-linear>
          </div>


        </div>
      </div>

      <!-- список реклам -->
      <template>
        <div class="row" v-if="advertisingProgramList.length > 0">
          <template v-if="isListView">
            <div class="col-2"
                 v-for="(iHouse, index)  in advertisingProgramList" :key="index"
            >
              <app-advertising-program-list-card
                  :house="iHouse"
                  @showDialog="showDialog($event)"
                  @openChangePositionWindow="openChangePositionWindow($event)"
              />
            </div>
          </template>
          <template v-else>
            <div class="col-3"
                 v-for="(iHouse, index)  in advertisingProgramList" :key="index"
            >
              {{ iHouse.address }}
            </div>
          </template>
        </div>

        <v-alert
            v-else
            dense
            icon="mdi-information-outline"
            text
            color="orange"
        ><span>Поиск не дал результата или сервер не отвечает={ Уточните пожалуйста параметры поиска.</span>
        </v-alert>
      </template>

      <!-- пагинация -->
      <div class="row">
        <div class="col-12">
          <v-pagination
              color="orange"
              circle
              v-model="filterAndSort.page"
              class="my-4"
              :length="pageCount"
          ></v-pagination>
        </div>
      </div>


      <!-- статистика по статусам -->
      <div class="row">
        <div class="col-12 text-center">
          <v-btn color="orange accent-1" fab @click="showStatistic = !showStatistic">
            <v-icon dark>
              mdi mdi-menu
            </v-icon>
          </v-btn>
        </div>
        <template v-if="showStatistic">
        <div class="col-12 py-0" v-for="(iStatus, index)  in statistic.statusStatistic" :key="index">
          <p
              class="py-1 my-0"
          >
            <b  @click="showDialog(iStatus.id)" class="pointer-cursor">{{ index + 1 }}. {{ iStatus.ap }} -  {{ iStatus.countElevator }} лифтов ({{ iStatus.statusDispaly }})</b>
          </p>
        </div>
          </template>
      </div>

      <!-- модалка -->

      <app-advertising-program-window
          ref="apWindow"
          :period="filterAndSort.period"
          @closeDialog="closeDialog($event)"
          @showAlert="$emit('showAlert', $event)"
      />

      <app-advertising-program-edit-window
          ref="apChangePositionWindow"
          @closeDialog="closeDialog($event)"
          @showAlert="$emit('showAlert', $event)"
      />

      <app-advertising-program-prolong-window
          ref="apProlongWindow"
          @closeDialog="closeDialog($event)"
          @showAlert="$emit('showAlert', $event)"
      />

      <app-advertising-program-merge-window
          ref="advertisingProgramMergeWindow"
          @closeDialog="closeDialog($event)"
          @showAlert="$emit('showAlert', $event)"
      />


    </div>

    <v-btn
        class="mx-2 orange btn-add"
        fab
        @click="showDialog()"
    >
      <v-icon dark>
        mdi-pencil-outline
      </v-icon>
    </v-btn>

    <v-btn
        class="mx-2 orange btn-prolong"
        fab
        @click="openApProlongWindow(filterAndSort.period)"
    >
      <v-icon dark>
        mdi-calendar-month-outline
      </v-icon>
    </v-btn>

    <v-btn
        class="mx-2 orange btn-merge"
        fab
        @click="openMergeWindow(filterAndSort.period)"
    >
      <v-icon dark>
        mdi-source-merge
      </v-icon>
    </v-btn>

    <v-btn class="mx-2 shadow btn-render-adhesive-sheet" color="orange" dark
           target="_blank"
           fab
           :href="linkRenderAdhesiveSheet"
    >
      <v-icon>mdi-file-excel</v-icon>
    </v-btn>

    <!--    <v-btn color="orange" @click="isListView = !isListView" dark class="ml-3 p-0"-->
    <!--           alt="Сменить вид">-->
    <!--      <v-icon>mdi-view-comfy</v-icon>-->
    <!--    </v-btn>-->

  </div>


</template>

<script>
import AppAdvertisingProgramWindow from "@/components/AppAdvertisingProgramWindow";
import AppAdvertisingProgramListCard from "@/components/AppAdvertisingProgramListCard";
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import AppAdvertisingProgramEditWindow from "@/components/AppAdvertisingProgramEditWindow";
import AppAdvertisingProgramProlongWindow from "@/components/AppAdvertisingProgramProlongWindow";
import AppAdvertisingProgramMergeWindow from "@/components/AppAdvertisingProgramMergeWindow";


export default {
  name: 'AdvertisingProgramView',

  components: {
    AppAdvertisingProgramEditWindow,
    AppAdvertisingProgramWindow,
    AppAdvertisingProgramProlongWindow,
    AppAdvertisingProgramMergeWindow,
    AppAdvertisingProgramListCard
  },

  data() {
    return {
      isLoad: true,
      ajaxLoad: false,
      showStatistic: false,

      periodList: [],
      advertisingProgramList: [],
      statistic: {},

      countHouseAll: 0,
      countHouseAfterFilter: 0,
      countElevatorAfterFilter: 0,
      countElevatorAll: 0,
      pageCount: 1,

      isListView: true,

      // сортировки и фильтры
      orderByList: [
        {id: 'address', label: 'Наименования от А до Я'},
        {id: '-address', label: 'Наименования от Я до А'},
        {id: '-order_index', label: 'Согласно КП'},
      ],
      bysyAndConflictList: [
        {id: '', label: '-'},
        {id: 'allBysy', label: 'Всё занято'},
        {id: 'hasFree', label: 'Есть свободные'},
        {id: 'hasFreeOrKenguru', label: 'Есть свободные или Кенгуру'},
        {id: 'overflow', label: 'Занято больше, чем мест в лифте'},
        {id: 'dontPosition', label: 'Конфликт. Нет позиции'},
        {id: 'crossConflict', label: 'Конфликт. Пересечение позиций'},
      ],
      hasPhotoList: [
        {id: '', label: '-'},
        {id: 'enough', label: 'Хватает'},
        {id: 'lack', label: 'Не хватает'},
        {id: 'overflow', label: 'Больше чем надо'},
      ],


      filterAndSort: {
        orderBy: '-order_index',
        page: 1,
        bysyAndConflict: '',
        searchAddress: '',
        hasPhoto: '',
        searchClient: '',
        period: '',

        clientType: '',
        statusAdvertisingProgram: '',
      },

    }
  },

  computed: {
    linkRenderAdhesiveSheet: function () {
      return `${LOCAL_CONFIG.urls.renderAdhesiveSheet}${this.filterAndSort.period}`
    }
  },

  methods: {
    async getPeriodList() {
      const url = `${LOCAL_CONFIG.urls.period}`;
      const response = await fetch(url, {method: 'get'});
      const data = await response.json();
      this.periodList = data.periodList;
      this.filterAndSort.period = data.periodNow;
    },

    openChangePositionWindow(id) {
      this.$refs.apChangePositionWindow.openDialog(id, this.filterAndSort.period);
    },

    openApProlongWindow(id) {
      this.$refs.apProlongWindow.openDialog(id, this.filterAndSort.period);
    },

    openMergeWindow(id) {
      this.$refs.advertisingProgramMergeWindow.openDialog(id, this.filterAndSort.period);
    },


    showDialog(id = null) {
      this.$refs.apWindow.openDialog(id);
    },

    setFilterClientType(clientType) {
      this.filterAndSort.statusAdvertisingProgram = ''
      if (this.filterAndSort.clientType === clientType) {
        this.filterAndSort.clientType = ''
      } else {
        this.filterAndSort.clientType = clientType;
      }
    },

    setFilterStatusAdvertisingProgram(status) {
      this.filterAndSort.clientType = ''
      if (this.filterAndSort.statusAdvertisingProgram === status) {
        this.filterAndSort.statusAdvertisingProgram = ''
      } else {
        this.filterAndSort.statusAdvertisingProgram = status;
      }
    },

    resetFilterAndSort() {
      this.filterAndSort = {
        orderBy: '-order_index',
        page: 1,
        searchAddress: '',
        bysyAndConflict: '',
        hasPhoto: '',
        searchClient: '',
        clientType: '',
        statusAdvertisingProgram: '',
        period: this.filterAndSort.period
      };
      this.getList();
    },

    closeDialog() {
      this.getList();
      this.getStatstic();
    },

    async getStatstic() {
      const url = `${LOCAL_CONFIG.urls.advertisingProgramStatistic}${this.filterAndSort.period}/`;
      const response = await fetch(url, {method: 'get'});
      this.statistic = await response.json();
    },

    async getList() {
      this.ajaxLoad = true;
      const url = `${LOCAL_CONFIG.urls.advertisingAndDetail}${this.filterAndSort.period}/?` + new URLSearchParams(this.filterAndSort);
      const response = await fetch(url, {method: 'get'});
      const data = await response.json();
      this.advertisingProgramList = data['data'];
      this.countHouseAll = data['countHouseAll'];
      this.countHouseAfterFilter = data['countHouseAfterFilter'];
      this.countElevatorAfterFilter = data['countElevatorAfterFilter'];
      this.countElevatorAll = data['countElevatorAll'];
      this.pageCount = data['pageCount']
      this.ajaxLoad = false;
      this.$emit('loadComplete',);

    }
  },

  async created() {
    await this.getPeriodList();
    // this.getStatstic();
    // await this.getList()

  },


  watch: {
    'filterAndSort.orderBy'() {
      this.filterAndSort.page = 1;
      this.getList();
    },
    'filterAndSort.clientType'() {
      this.filterAndSort.page = 1;
      this.getList();
    },
    'filterAndSort.statusAdvertisingProgram'() {
      this.filterAndSort.page = 1;
      this.getList();
    },
    'filterAndSort.hasPhoto'() {
      this.filterAndSort.page = 1;
      this.getList();
    },
    'filterAndSort.bysyAndConflict'() {
      this.filterAndSort.page = 1;
      this.getList();
    },
    'filterAndSort.searchClient'() {
      this.filterAndSort.page = 1;
      this.getList();
    },
    'filterAndSort.period'() {
      this.filterAndSort.page = 1;
      this.getList();
      this.getStatstic();
    },
    'filterAndSort.page'() {
      this.getList();
    }
  },


}
</script>

<style lang="scss" scoped>

.avatarka {
  width: 80px;
  align-self: start;
  margin-top: 10px;
}

.scrollarea {
  overflow-y: auto;
  overflow-x: hidden;
}

.btn-add {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #fff !important;
}

.btn-prolong {
  position: fixed;
  bottom: 90px;
  right: 20px;
  color: #fff !important;
}

.btn-merge {
  position: fixed;
  bottom: 230px;
  right: 20px;
  color: #fff !important;
}

.btn-render-adhesive-sheet {
  position: fixed;
  bottom: 160px;
  right: 20px;
  color: #fff !important;
}

.ap-view {
  .v-chip.v-size--small {
    font-size: 11.5px !important;
  }
}
</style>
