<template>
  <div class="position-relative" :class="{'wrap':dialogShow}">
    <v-dialog
        :value="dialogShow"
        persistent
        scrollable
        max-width="1200px"
    >
      <v-card>

        <v-card-title class="text-center bg-light ">
          <div class="h3 ">Объединение рекламных программ</div>
          <div class="ms-auto">
            <button class="close-btn" @click="closeDialog">
              <v-icon>
                mdi-close-circle
              </v-icon>
            </button>
          </div>
        </v-card-title>

        <v-card-text>
          <v-card
              color="basil"
              flat
              class="py-3 "
          >
            <div class="row">
              <div class="col-12 pb-1">
                <b>Шаг 1. Выберите рекламные программы для объединения </b>
              </div>
              <div class="col-4">

                <v-select
                    label="Период"
                    v-model="period"
                    item-text="name"
                    disabled
                    item-value="slug"
                    :items="periodList"
                ></v-select>
              </div>
              <div class="col-4">
                <v-autocomplete
                    label="РП с которой объединяем(первая РП)*"
                    v-model="advertisingProgramFirst"
                    item-text="name"
                    item-value="id"
                    :items="advertisingProgramFirstList"
                    @change="getAdvertisingProgramSecondList()"
                ></v-autocomplete>

              </div>

              <div class="col-4">
                <v-autocomplete
                    label="РП которую объединяем(вторая РП)*"
                    v-model="advertisingProgramSecond"
                    item-text="name"
                    item-value="id"
                    no-data-text="Не найдено с чем объединить"
                    :items="advertisingProgramSecondList"
                    @change="getAdvertisingProgramHouseList()"
                ></v-autocomplete>

              </div>


            </div>

            <hr v-if="advertisingProgramSecond">

            <v-progress-linear
                indeterminate
                :active="ajaxLoad"
                class="mt-3"
                color="yellow darken-2"
            ></v-progress-linear>

            <div class="row" v-if="advertisingProgramSecond">
              <div class="col-12">
                <b>Шаг 2. Проверьте список домов которые попадут из второй РП в первую</b>
              </div>


              <div class="col-12">
                <v-simple-table
                    light
                    fixed-header
                    height="350px"
                >
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">
                        Адрес
                      </th>
                      <th class="text-left">
                        Статус был
                      </th>
                      <th class="text-left">
                        Статус станет
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in advertisingProgramHouseList"
                        :key="item.id"
                    >
                      <td>
                        {{ item }}
                      </td>
                      <td>
                        {{ secondStatus }}
                      </td>
                      <td>
                        {{ firstStatus }}
                      </td>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>


            <hr v-if=" advertisingProgramSecond">

            <div class="row" v-if="advertisingProgramSecond">
              <div class="col-10">
                <v-alert
                    dense
                    icon="mdi-information-outline"
                    text
                    color="orange"
                ><span
                    class=" mt-3 text-dark">Внимание. После объединения РП которую объединяем будет отправлена в архив</span>
                </v-alert>

              </div>
              <div class="col-2 text-right">
                <v-btn
                    color="orange"
                    dark
                    v-if="!ajaxLoad"
                    @click="merge()"
                >
                  <v-icon>mdi-source-merge</v-icon>
                  Объединить
                </v-btn>
              </div>
            </div>


          </v-card>
        </v-card-text>


      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "AppAdvertisingProgramMergeWindow",
  data() {
    return {
      ajaxLoad: false,
      dialogShow: false,
      periodList: [],
      advertisingProgramFirst: '',
      advertisingProgramSecond: null,
      period: '',
      advertisingProgramHouseList: null,
      advertisingProgramFirstList: [],
      advertisingProgramSecondList: null,
      firstStatus: '-',
      secondStatus: '-',

    }
  },


  methods: {
    closeDialog: function () {
      this.dialogShow = false;
      this.advertisingProgramFirst = '';
      this.advertisingProgramSecond = null;
      this.advertisingProgramHouseList = null;
      this.firstStatus = '-';
      this.secondStatus = '-';

      this.$emit('closeDialog', {});
    },

    async getPeriodList() {
      const url = `${LOCAL_CONFIG.urls.period}`;
      const response = await fetch(url, {method: 'get'});
      const data = await response.json();
      this.periodList = data.periodList;
    },

    async getAdvertisingProgramFirstList() {
      this.ajaxLoad = true;
      const url = `${LOCAL_CONFIG.urls.advertisingProgramMergeGetFirst}${this.period}/`;
      const response = await fetch(url, {method: 'get'});
      this.advertisingProgramFirstList = await response.json();
      this.ajaxLoad = false;
    },

    async getAdvertisingProgramSecondList() {
      this.ajaxLoad = true;
      this.advertisingProgramSecondList = null;
      this.advertisingProgramHouseList = null;
      this.advertisingProgramSecond = null;
      this.firstStatus = '-';
      this.secondStatus = '-';
      const url = `${LOCAL_CONFIG.urls.advertisingProgramMergeGetSecond}${this.period}/${this.advertisingProgramFirst}/`;
      const response = await fetch(url, {method: 'get'});
      this.advertisingProgramSecondList = await response.json();
      this.ajaxLoad = false;
    },

    async getAdvertisingProgramHouseList() {
      if (this.advertisingProgramSecond) {
        this.ajaxLoad = true;
        this.firstStatus = '-';
        this.secondStatus = '-';
        this.advertisingProgramHouseList = null;
        const url = `${LOCAL_CONFIG.urls.advertisingProgramMergeGetHouseList}${this.period}/${this.advertisingProgramFirst}/${this.advertisingProgramSecond}/`;
        const response = await fetch(url, {method: 'get'});
        const data = await response.json();
        this.advertisingProgramHouseList = data.houseList;
        this.firstStatus = data.firstStatus;
        this.secondStatus = data.secondStatus;
        this.ajaxLoad = false;
      }

    },

    async merge() {
      if (this.advertisingProgramSecond) {
        this.ajaxLoad = true;
        const url = `${LOCAL_CONFIG.urls.advertisingProgramMerge}${this.period}/${this.advertisingProgramFirst}/${this.advertisingProgramSecond}/`;
        const response = await fetch(url, {method: 'get'});
        const data = await response.json();
        this.$emit('showAlert', data.status)
        if (data.status === 'Объединение прошло успешно') {
          this.closeDialog()
        }
        this.ajaxLoad = false;
      }
    },


    async openDialog(period) {
      this.period = period;
      this.getPeriodList();
      this.getAdvertisingProgramFirstList();
      this.dialogShow = true;
    },


    async save() {
      if (!this.periodAfter) {
        this.$emit('showAlert', 'Вы не выбрали не период на шаге 1');
        return false;
      }

      if (!this.advertisingProgramFirst) {
        this.$emit('showAlert', 'Вы не выбрали РП с которой будет объединение');
        return false;
      }

      if (!this.advertisingProgramFirst) {
        this.$emit('showAlert', 'Вы не выбрали РП которую будем объединять');
        return false;
      }


      this.ajaxLoad = true;
      const url = `${LOCAL_CONFIG.urls.advertisingProgramMerge}${this.period}/`;
      try {
        const r = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            // 'saveData': this.saveData,
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });

        this.ajaxLoad = false;

        if (r.status > 201) {
          this.$emit('showAlert', await r.text());
        } else {
          this.$emit('showAlert', 'Данные сохранены')
          this.closeDialog()
        }
      } catch (error) {
        this.$emit('showAlert', 'Ошибка при обращение к серверу')
      }
    }

  },


}
</script>

<style scoped>
/*.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {*/
/*  background: none !important;*/
/*}*/


</style>