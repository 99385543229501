<template>
  <div class="position-relative" :class="{'wrap':dialogShow}">
    <v-dialog
        :value="dialogShow"
        persistent
        scrollable
        max-width="1000px"
    >
      <v-card>

        <v-card-title class="text-center bg-light ">
          <div class="h3 ">{{ changeData.address }}</div>
          <div class="ms-auto">
            <button class="close-btn" @click="closeDialog">
              <v-icon>
                mdi-close-circle
              </v-icon>
            </button>
          </div>
        </v-card-title>

        <v-card-text>
          <v-card
              color="basil"
              flat
              class="py-3 "
          >
            <v-progress-linear
                indeterminate
                :active="ajaxLoad"
                class="mt-3"
                color="yellow darken-2"
            ></v-progress-linear>

            <v-simple-table
                light
                fixed-header
                height="630px"
                v-if="changeData.advertisingProgramHouseList.length > 0"
            >
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Рекламная программа
                  </th>
                  <th class="text-left" width="100">
                    Позиция
                  </th>
                  <th width="100" class="text-center"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in changeData.advertisingProgramHouseList"
                    :key="item.id"
                >
                  <td>
                    <v-autocomplete
                        v-model="item.advertisingProgram"
                        class="pointer-cursor"
                        item-text="name"
                        item-value="id"
                        :items="changeData.advertisingProgramList"
                    />
                  </td>
                  <td>
                    <v-text-field
                        v-model="item.position"
                        type="number"
                        aria-valuemax="8"
                    />

                  </td>
                  <td>
                    <v-btn class="mr-3 " color="orange" text fab @click="deleteItem(item)">
                      <v-icon
                          large
                          color="orange darken-2"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </td>
                  <!--                  <td>-->
                  <!--                    <v-btn class="mr-3 " color="orange" text fab>-->
                  <!--                      <v-icon-->
                  <!--                          large-->
                  <!--                          color="orange darken-2"-->
                  <!--                      >-->
                  <!--                        mdi-menu-up-->
                  <!--                      </v-icon>-->
                  <!--                    </v-btn>-->

                  <!--                    <v-btn class="mr-3 " color="orange" text fab>-->
                  <!--                      <v-icon-->
                  <!--                          large-->
                  <!--                          color="orange darken-2"-->
                  <!--                      >-->
                  <!--                        mdi-menu-down-->
                  <!--                      </v-icon>-->
                  <!--                    </v-btn>-->
                  <!--                  </td>-->
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-alert v-else
                     dense
                     icon="mdi-information-outline"
                     text
                     color="orange"
            ><span
                class=" mt-3 text-dark">хмммм... Как странно, но данных нет... А у дома точна есть реклама? -_-</span>
            </v-alert>


    <div class="row mt-3">
      <div class="col-6">
          <v-btn
                color="orange"
                dark
                class="mr-4"
                @click="addItem()"
            >
              <v-icon>mdi mdi-plus-circle-outline</v-icon>
              Добавить
            </v-btn>

         <v-btn
                color="orange"
                dark
                class=""
                @click="autoNum()"
            >
              <v-icon></v-icon>
              Пронумеровать
            </v-btn>
      </div>
      <div class="col-6 text-right ">
        <v-btn
                color="orange"
                dark
                @click="save"
            >
              <v-icon>mdi mdi-content-save</v-icon>
              Сохранить
            </v-btn>
      </div>
    </div>






          </v-card>
        </v-card-text>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "AppAdvertisingProgramEditWindow",
  data() {
    return {
      ajaxLoad: true,
      dialogShow: false,
      changeData: {
        id: undefined,
        address: '',
        advertisingProgramHouseList: [],
        advertisingProgramList: [],

      },
      period: '',
      houseId: '',


    }
  },

  computed: {
    elevatorPositionList: function () {
      const result = [{name: 'Без места', 'id': null}];
      for (let i = 1; i <= 8; i++) {
        result.push({name: i, 'id': i});
      }
      return result
    }
  },

  methods: {
    closeDialog: function () {
      this.dialogShow = false;
      this.changeData = {
        id: undefined,
        address: '',
        advertisingProgramHouseList: [],
        advertisingProgramList: []
      };

      this.$emit('closeDialog', {});
    },

    async geServerData(houseId, period) {
      this.period = period;
      this.ajaxLoad = true;
      const url = `${LOCAL_CONFIG.urls.advertisingProgramEdit}${period}/${houseId}/`;
      const response = await fetch(url, {method: 'get'});
      this.changeData = await response.json();
      this.ajaxLoad = false;
    },

    async openDialog(houseId, period) {
      await this.geServerData(houseId, period)
      this.dialogShow = true;
      this.houseId = houseId;
    },

    deleteItem(delItem) {
      this.changeData.advertisingProgramHouseList = this.changeData.advertisingProgramHouseList.filter(item => item !== delItem)
    },

    addItem(){
      this.changeData.advertisingProgramHouseList.push({
        position:null,
        advertisingProgram: ''
      })
    },

    autoNum(){
      let i = 1;
      this.changeData.advertisingProgramHouseList.forEach(item => {
        item.position = i;
        i+= 1;
      })
    },

    async save() {
      // сперва удаляем пустые
      this.changeData.advertisingProgramHouseList = this.changeData.advertisingProgramHouseList.filter(item => item.advertisingProgram !== "")
      const url = `${LOCAL_CONFIG.urls.advertisingProgramEdit}${this.period}/${this.houseId}/`;
      try {
        const r = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(this.changeData.advertisingProgramHouseList),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });

        if (r.status > 201) {
          this.$emit('showAlert', await r.text());
        } else {
          this.$emit('showAlert', 'Данные сохранены')
          this.closeDialog()
        }
      } catch (error) {
        this.$emit('showAlert', 'Ошибка при обращение к серверу')
      }
    }

  }
}
</script>

<style scoped>
/*.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {*/
/*  background: none !important;*/
/*}*/


</style>