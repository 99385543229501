<template>
  <div class="position-relative" :class="{'wrap':dialogShow}">
    <v-dialog
        :value="dialogShow"
        persistent
        scrollable
        max-width="1200px"
    >
      <v-card>

        <v-card-title class="text-center bg-light ">
          <div class="h3 ">Продление рекламных программ</div>
          <div class="ms-auto">
            <button class="close-btn" @click="closeDialog">
              <v-icon>
                mdi-close-circle
              </v-icon>
            </button>
          </div>
        </v-card-title>

        <v-card-text>
          <v-card
              color="basil"
              flat
              class="py-3 "
          >
            <div class="row">
              <div class="col-12 pb-1">
                <b>Шаг 1. Выберите период в который продлеваем и статус переноса</b>
              </div>
              <div class="col-4">

                <v-select
                    label="Выбранный период"
                    v-model="periodBefore"
                    item-text="name"
                    disabled
                    item-value="slug"
                    :items="periodList"
                ></v-select>
              </div>
              <div class="col-4">
                <v-select
                    label="Период в который продлеваем*"
                    v-model="periodAfter"
                    item-text="name"
                    item-value="slug"
                    :items="periodAfterList"
                ></v-select>

              </div>
              <div class="col-4">
                <v-select
                    label="Применить статус*"
                    v-model="statusAp"
                    item-text="label"
                    item-value="id"
                    :items="statusApList"
                ></v-select>
              </div>


            </div>

            <hr v-if="periodAfter">

            <v-progress-linear
                indeterminate
                :active="ajaxLoad"
                class="mt-3"
                color="yellow darken-2"
            ></v-progress-linear>

            <div class="row" v-if="periodAfter">
              <div class="col-12">
                <b>Шаг 2. Выберите программы для переноса</b>
              </div>

              <div class="col-4 pb-0">
                <v-text-field label="Наименование" @keydown.enter="getServerData" @blur="getServerData"
                              v-model="filterAndSort.name"></v-text-field>
              </div>

              <div class="col-4 pb-0">
                <v-select
                    label="Тип"
                    v-model="filterAndSort.clientType"
                    item-text="label"
                    item-value="id"
                    :items="clientTypeList"
                ></v-select>
              </div>

              <div class="col-4 pb-0">
                <v-select
                    label="Статус"
                    v-model="filterAndSort.statusAdvertisingProgram"
                    item-text="label"
                    item-value="id"
                    :items="statusAdvertisingProgramList"
                ></v-select>
              </div>


              <div class="col-12">
                <v-simple-table
                    light
                    fixed-header
                    height="350px"
                >
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">
                        Рекламная программа
                      </th>
                      <th class="text-left">
                        Тип
                      </th>
                      <th class="text-left">
                        Статус
                      </th>
                      <th class="text-left" width="100">
                        Переносить?
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in advertisingProgramHouseList"
                        :key="item.id"
                        :class="{
                                  // 'text-primary':iAdvertisingProgram.clientType === 1,
                                  'text-info':item.clientType === 2,
                                  'text-success':item.clientType === 3,
                                  'text-warning':item.clientType === 4,
                                }"
                    >
                      <td>
                        {{ item.advertisingProgramDisplay }}
                      </td>
                      <td>
                        {{ item.typeDisplay }}
                      </td>
                      <td>
                        {{ item.statusDisplay }}
                      </td>
                      <td>
                        <v-switch
                            class="m-0 p-0"
                            color="orange"
                            hide-details
                            :value="item.id"
                            v-model="saveData"
                        />

                      </td>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>

            <div class="row" v-if="periodAfter">
              <div class="col-6">
                <v-btn
                    class="mr-3"
                    color="orange"
                    dark
                    outlined
                    text
                    @click="checkSelect()"
                >
                  <v-icon>mdi-checkbox-marked-outline</v-icon>
                  Отметить выбранные
                </v-btn>

                <v-btn
                    color="orange"
                    dark
                    outlined
                    text
                    @click="uncheckSelect()"
                >
                  <v-icon>mdi-checkbox-blank-outline</v-icon>
                  Снять выбранные
                </v-btn>
              </div>
            </div>

            <hr v-if="periodAfter">

            <div class="row" v-if="periodAfter">
              <div class="col-10">
                <v-alert
                    dense
                    icon="mdi-information-outline"
                    text
                    color="orange"
                ><span
                    class=" mt-3 text-dark">Внимание. Если в период в который Вы продлеваете уже была выбранная РП. <br>То программа создаст ещё одну копию</span>
                </v-alert>

              </div>
              <div class="col-2 text-right">
                <v-btn
                    color="orange"
                    dark
                    v-if="!ajaxLoad"
                    @click="save"
                >
                  <v-icon>mdi mdi-content-save</v-icon>
                  Продлить
                </v-btn>
              </div>
            </div>


          </v-card>
        </v-card-text>


      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "AppAdvertisingProgramProlongWindow",
  data() {
    return {
      ajaxLoad: false,
      dialogShow: false,
      periodList: [],
      statusApList: LOCAL_CONFIG.statusAdvertisingProgramList,
      statusAp: '',
      periodBefore: '',
      periodAfter: '',
      filterAndSort: {
        'clientType': '',
        'statusAdvertisingProgram': '',
        'name': '',
      },
      clientTypeList: [{id: '', label: '-'}, ...LOCAL_CONFIG.clientTypeList],
      statusAdvertisingProgramList: [{id: '', label: '-'}, ...LOCAL_CONFIG.statusAdvertisingProgramList],
      saveData: [],
      advertisingProgramHouseList: []
    }
  },

  computed: {
    periodAfterList: function () {
      return this.periodList.filter(item => item.slug !== this.periodBefore);
    },

    advertisingProgramHouseListLocal: function () {
      return this.advertisingProgramHouseList;
    },
  },

  methods: {
    closeDialog: function () {
      this.dialogShow = false;
      this.saveData = [];
      this.filterAndSort = {
        'clientType': '',
        'statusAdvertisingProgram': '',
        'name': '',
      };

      this.$emit('closeDialog', {});
    },

    async getPeriodList() {
      const url = `${LOCAL_CONFIG.urls.period}`;
      const response = await fetch(url, {method: 'get'});
      const data = await response.json();
      this.periodList = data.periodList;

    },

    async getServerData(param) {
      if (this.periodBefore && this.periodAfter) {
        this.ajaxLoad = true;
        const url = `${LOCAL_CONFIG.urls.advertisingProgramProlong}${this.periodBefore}/${this.periodAfter}/?` + new URLSearchParams(this.filterAndSort);
        const response = await fetch(url, {method: 'get'});
        this.ajaxLoad = false;
        const data = await response.json();
        this.advertisingProgramHouseList = data.advertisingProgramHouseList;
        if (param.inFirst){
          this.saveData = data.prolongList;
        }


      }
    },

    async openDialog(periodBefore) {
      this.periodBefore = periodBefore;
      this.getServerData({'inFirst':true});
      this.getPeriodList();
      this.dialogShow = true;
    },

    uncheckSelect: function () {
      for (const item of this.advertisingProgramHouseListLocal) {
        this.saveData = this.saveData.filter(i => i !== item.id);
      }
    },

    checkSelect: function () {
      for (const item of this.advertisingProgramHouseListLocal) {
        if (this.saveData.indexOf(item.id) === -1) {
          this.saveData.push(item.id)
        }
      }

    },


    async save() {
      if (!this.periodAfter) {
        this.$emit('showAlert', 'Вы не выбрали не период на шаге 1');
        return false;
      }

      if (!this.statusAp) {
        this.$emit('showAlert', 'Вы не выбрали статус на шаге 1');
        return false;
      }

      if (this.saveData.length === 0) {
        this.$emit('showAlert', 'Вы не выбрали не одну РП на шаге 2');
        return false;
      }

      this.ajaxLoad = true;
      const url = `${LOCAL_CONFIG.urls.advertisingProgramProlong}${this.periodBefore}/${this.periodAfter}/`;
      try {
        const r = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            'saveData': this.saveData,
            'statusAp': this.statusAp,
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });

        this.ajaxLoad = false;

        if (r.status > 201) {
          this.$emit('showAlert', await r.text());
        } else {
          this.$emit('showAlert', 'Данные сохранены')
          this.closeDialog()
        }
      } catch (error) {
        this.$emit('showAlert', 'Ошибка при обращение к серверу')
      }
    }

  },

  watch: {
    'filterAndSort.clientType'() {
      this.getServerData();
    },
    'filterAndSort.statusAdvertisingProgram'() {
      this.getServerData();
    },
    'periodAfter'() {
      this.getServerData({'inFirst':true});
    },
  }

}
</script>

<style scoped>
/*.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {*/
/*  background: none !important;*/
/*}*/


</style>