<template>
  <div id="app">
    <v-app class="w-100">
      <v-navigation-drawer app width="225">
        <app-navbar :user="user"/>
      </v-navigation-drawer>

      <v-main>

        <app-loader v-if="!isLoad"/>

        <router-view @loadComplete="isLoad = true"
                     :class="{'d-none':!isLoad}"
                     :user="user"
                     @showAlert="showAlert($event)"
        />


        <v-snackbar
            color="white"
            v-model="snackbar"
        >
          <span class="text-dark">{{ snackbarText }}</span>

          <template v-slot:action="{ attrs }">
            <v-btn
                color="orange accent-2"
                tile
                v-bind="attrs"
                @click="snackbar = false"
            >
              Ок
            </v-btn>
          </template>
        </v-snackbar>

      </v-main>
    </v-app>

  </div>
</template>

<style lang="scss" src="./assets/style.scss">
</style>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";
import AppNavbar from "@/components/AppNavbar";
import AppLoader from "@/components/AppLoader";


export default {

  created: async function () {
    await this.checkUser();
  },

  components: {
    AppNavbar,
    AppLoader,
  },

  methods: {

    showAlert(text){
      this.snackbar = true
      this.snackbarText = text;
    },


    async checkUser() {
      const sessionId = CookieHelper.getCookie('sessionid');
      if (sessionId === undefined) {
        this.isLogin = false;
        return false;
      }

      const url = LOCAL_CONFIG.urls.checkUser + `${sessionId}/`;
      const response = await fetch(url, {method: 'get'});
      const user = await response.json();
      if (user.isLogin === true) {
        this.isLogin = true;
        this.user = {
          userName: user['userName'],
          isAdmin: user['isAdmin']
        }
      } else {
        this.isLogin = false;
      }
    }

  },

  data() {
    return {
      isLogin: false,
      isLoad: false,
      user: {
        isAdmin: false,
        userName: null
      },

      snackbar: false,
      snackbarText: ""
    }
  },

  watch: {
    '$route.path'() {
      this.isLoad = false;
    }
  }

}
</script>