<template>
  <v-card
      outlined
      elevation="7"
      class="shadow pt-3 pb-4 px-3 ap-list-card h-100"
  >
    <div class="row">
      <div class="col-9 pt-0 pb-3">
        <v-list-item-subtitle class=""><b>{{ house.address }} <br>(лифтов: {{ house.elevatorCount }}
          шт.) </b>
        </v-list-item-subtitle>
      </div>
      <div class="col-3 pt-0 pb-3 text-right">
          <v-icon color="orange" dark @click="$emit('openChangePositionWindow', house.houseId)">mdi-pencil-outline</v-icon>
      </div>
    </div>

    <hr class="my-2 mb-1">


    <div class="row">
      <div class="col-12 small py-1">
        <div class="m-0">


          <div class="my-1" v-for="(iAdvertisingProgram, index)  in house.advertisingProgram" :key="index">
            <b
                class="pointer-cursor"
                :class="{
                                  // 'text-primary':iAdvertisingProgram.clientType === 1,
                                  'text-info':iAdvertisingProgram.clientType === 2,
                                  'text-success':iAdvertisingProgram.clientType === 3,
                                  'text-warning':iAdvertisingProgram.clientType === 4,
                                }"
                @click="showDialog(iAdvertisingProgram.advertisingProgramId)"
            >
              <span v-if="iAdvertisingProgram.position"> {{ iAdvertisingProgram.position }}<span
                  v-if="iAdvertisingProgram.elevatorPosition">(фикс)</span></span> <b
                class="text-danger" v-else>БЕЗ МЕСТА</b> . {{ iAdvertisingProgram.clientName }}
              {{ iAdvertisingProgram.note }}
            </b>
          </div>

          <div class="mt-1">
            <div class="mb-2">
              <v-chip
                  v-if="house.freeArea === 0"
                  small
                  color="success"
              >
                Свободно площадок {{ house.freeArea }} из {{ house.advertisingSpaceA4Count }}
              </v-chip>
              <v-chip
                  v-else-if="house.freeArea < 0"
                  small
                  color="red"
                  dark
              >
                Свободно площадок {{ house.freeArea }} из {{ house.advertisingSpaceA4Count }}
              </v-chip>
              <v-chip
                  v-else
                  small
                  color="warning"
              >
                Свободно площадок {{ house.freeArea }} из {{ house.advertisingSpaceA4Count }}
              </v-chip>
            </div>

            <div class="">
              <v-chip
                  v-if="house.lackPhoto === 0"
                  color="success"
                  small
              >Фотографий {{ house.photoCount }} из {{ house.elevatorCount }}
              </v-chip>

              <v-chip
                  v-else-if="house.lackPhoto > 0"
                  color="warning"
                  small
              >Фотографий {{ house.photoCount }} из {{ house.elevatorCount }}
              </v-chip>

              <v-chip
                  v-else
                  dark
                  color="red"
                  small
              >Фотографий {{ house.photoCount }} из {{ house.elevatorCount }}
              </v-chip>

            </div>

            <div class="mt-2"   v-if="house.crossConflict || house.dontPosition ">
              <v-chip

                  dark
                  color="red"
                  small
              >Конфликт размещения
              </v-chip>
            </div>
          </div>
        </div>

      </div>

    </div>

  </v-card>
</template>

<script>
export default {
  name: "AppAdvertisingProgramListCard",
  props: ['house',],

  methods: {
    // если 0, то новый клиент
    showDialog(id = 0) {
      this.$emit('showDialog', id);
    },
  }


}
</script>

<style scoped lang="scss">
.ap-list-card {

  .v-list-item__subtitle {
    font-size: 9pt;
  }

  .small{
    font-size: 9pt;
  }

}
</style>