// let baseUrl = 'http://127.0.0.1:8000';
let baseUrl = 'https://crm.ikenguru.ru';
// let baseUrl = '';
export default {
    baseUrl: baseUrl,

    urls: {
        //пользователи CRM
        checkUser: `${baseUrl}/api1/user_crm/check_user/`,
        login: `${baseUrl}/api1/user_crm/login/`,
        logout: `${baseUrl}/api1/user_crm/logout/`,

        //дома и лифты
        house: `${baseUrl}/api1/house/`,
        houseCreatePhotoDirFtp: `${baseUrl}/api1/house/create_photo_dir_ftp/`,
        paperSize: `${baseUrl}/api1/paper_size/`,
        liftboardLayout: `${baseUrl}/api1/liftboard_layout/`,
        placemarker: `${baseUrl}/api1/placemarker/`,

        //клиенты
        client: `${baseUrl}/api1/client/`,
        clientAutocomplite: `${baseUrl}/api1/client/autocomplite/`,

        //реклама
        advertisingAndDetail: `${baseUrl}/api1/advertising_and_detail/`,
        advertisingProgram: `${baseUrl}/api1/advertising_program/`,
        advertisingProgramStatistic: `${baseUrl}/api1/advertising_program_statistic/`,
        advertisingProgramEdit: `${baseUrl}/api1/advertising_program_edit/`,

        advertisingProgramProlong: `${baseUrl}/api1/advertising_program_prolong/`,

        advertisingProgramMerge: `${baseUrl}/api1/advertising_program_merge/`,
        advertisingProgramMergeGetFirst: `${baseUrl}/api1/advertising_program_merge_get_first/`,
        advertisingProgramMergeGetSecond: `${baseUrl}/api1/advertising_program_merge_get_second/`,
        advertisingProgramMergeGetHouseList: `${baseUrl}/api1/advertising_program_merge_get_house_list/`,

        advertisingProgramChangeComplete: `${baseUrl}/api1/advertising_program_change_complete/`,

        period: `${baseUrl}/api1/period/`,
        freeSpaceElevator: `${baseUrl}/api1/free_space_elevator/`,

        //отчеты
        reportFreeElevators: `${baseUrl}/api1/report/free_elevator/`,
        renderReportFreeArea: `${baseUrl}/api1/report/render_report_free_area/`,
        renderAdhesiveSheet: `${baseUrl}/api1/report/render_adhesive_sheet/`,

        //маркеты
        market: `${baseUrl}/api1/market/`,
        marketUpdate: `${baseUrl}/api1/market/update/`,
        marketUpdateAll: `${baseUrl}/api1/market/update_all`,

        marketSimpleView: `${baseUrl}/market_page`,


    },


    clientTypeList: [
        {id: 1, label: 'Рекламодатель'},
        {id: 2, label: 'ТСЖ/УК'},
        {id: 3, label: 'Партнер'},
        {id: 4, label: 'Кенгуру'},
    ],

    marketStatus: [
        {id: 1, label: 'Не было первичного подключения от клиента'},
        {id: 2, label: 'Не указаны настройки аудио девайса на клиенте'},
        {id: 3, label: 'Всё работает'},
        {id: 4, label: 'Нет подключения по сети'},
        {id: 5, label: 'Не найдено звуковое устройство'},
    ],

    statusAdvertisingProgramList: [
        {id: 1, label: 'Новая'},
        {id: 2, label: 'Продление без смены макета'},
        {id: 3, label: 'Продление и смена макета'},
    ],

    yesNoList: [
        {id: true, label: 'Да'},
        {id: false, label: 'Нет'},
    ],

    yesNoNullList: [
        {id: '', label: '-'},
        {id: true, label: 'Да'},
        {id: false, label: 'Нет'},
    ],


}