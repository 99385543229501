<template>
  <div :class="{'wrap':dialogShow}">
    <v-dialog
        :value="dialogShow"
        persistent
        scrollable
        max-width="1200px"
    >
      <v-card>
        <v-card-title class="text-center bg-light ">
          <div class="h3 " v-if="client.id">{{ client.nazvanie }}</div>
          <div class="h3 " v-else>Новый клиент</div>

          <div class="ms-auto">
            <button class="close-btn" @click="closeDialog">
              <v-icon>
                mdi-close-circle
              </v-icon>
            </button>
          </div>
        </v-card-title>

        <v-card-text>
          <v-tabs
              v-model="tab"
              background-color="transparent"
              color="#ffa900"
              grow
          >
            <v-tab>
              Контакты
            </v-tab>
            <v-tab v-if="client.id">
              Фотоотчеты и РП
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
            >
              <v-card
                  color="basil"
                  flat
                  class="py-3 "
              >

                <div class="row">

                  <div class="col-4">
                    <v-text-field label="Наименование*"
                                  v-model="client.name"></v-text-field>
                  </div>

                  <div class="col-4">
                    <v-text-field label="ИНН"
                                  v-model="client.inn"></v-text-field>
                  </div>

                  <div class="col-4">
                    <v-select
                        label="Тип клиента*"
                        v-model="client.clientType"
                        item-text="label"
                        item-value="id"
                        :items="clientTypeList"
                    ></v-select>
                  </div>

                  <div class="col-4">
                    <v-text-field label="ФИО ответственного лица"
                                  v-model="client.fioResponsible"></v-text-field>
                  </div>

                  <div class="col-4">
                    <v-text-field label="Телефон ответственного лица"
                                  v-model="client.phoneResponsible"></v-text-field>
                  </div>

                  <div class="col-4">
                    <v-text-field label="Email ответственного лица"
                                  v-model="client.emailResponsible"></v-text-field>
                  </div>


                  <div class="col-12">
                    <v-textarea outlined rows="3" label="Примечание"
                                v-model="client.note"></v-textarea>
                  </div>


                  <div class="col-12">
                    <v-btn
                        color="warning"
                        dark
                        @click="saveClient()"
                    >
                      <v-icon>
                        mdi-content-save
                      </v-icon>
                      Сохранить
                    </v-btn>
                  </div>

                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item v-if="client.id">
              <v-simple-table class="mt-3" v-if="client.advertisingProgramList.length > 0">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Период
                    </th>
                    <th class="text-center">
                      Бюджет
                    </th>
                    <th class="text-center">
                      Домов
                    </th>
                    <th class="text-center">
                      Лифтов
                    </th>
                    <th class="text-center">
                      Фотоотчет
                    </th>
                    <th class="text-center">
                      Список домов
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in client.advertisingProgramList"
                      :key="item.period"
                  >
                    <td>{{ item.period }} ({{item.rpCount}} рп.)</td>
                    <td class="text-center">{{ item.budget }}</td>
                    <td class="text-center">{{ item.houseCount }}</td>
                    <td class="text-center">{{ item.elevatorCount }}</td>
                    <td class="text-center"><a class="text-warning" :href="item.photoReportLink" target="_blank"><b>
                      {{ item.photoReportLink }}</b></a></td>
                    <td class="text-center"><a class="text-warning" :href="item.excelReportLink" target="_blank"><b>
                      Скачать Excel</b></a></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-alert
                  v-else
                  dense
                  icon="mdi-information-outline"
                  text
                  color="orange"
                  class="mt-3"
              ><span>У клиента пока нет рекламных программ</span>
              </v-alert>
            </v-tab-item>
          </v-tabs-items>

        </v-card-text>


      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";


export default {
  name: "AppClientWindow",
  data() {
    return {
      tab: 0,
      client: {},
      dialogShow: false,
      clientTypeList: LOCAL_CONFIG.clientTypeList,
      yesNoList: LOCAL_CONFIG.yesNoList,
    }
  },

  methods: {

    resetClient: function() {
      this.tab = 0;
      this.client = {
        id: null,
        name: '',
        accessToken: '',
        clientType: 1,
        inn: '',
        fioResponsible: '',
        phoneResponsible: '',
        emailResponsible: '',
        note: '',
        advertisingProgramList: [],
      }
    },

    closeDialog: function () {
      this.dialogShow = false;
      this.resetClient();
      this.$emit('closeDialog', {});
    },

    async openDialog(id) {
      if (id) {
        await this.getClient(id)
      } else {
        this.resetClient();
      }
      this.dialogShow = true;
    },

    async getClient(id) {
      const url = `${LOCAL_CONFIG.urls.client}${id}/`;
      const r = await fetch(url);
      this.client = await r.json();
    },

    validateSaveData() {
      const is1 = this.client.name !== undefined && this.client.name.length > 0;
      const is2 = this.client.clientType !== undefined && this.clientType !== '';
      return is1 && is2;
    },

    async saveClient() {
      const tmp = this.client.id ? `${this.client.id}/` : "";
      const method = this.client.id ? 'PUT' : "POST";
      if (!this.validateSaveData()) {
        this.$emit('showAlert', 'Не все поля  со звездочкой * заполнены');
        return false;
      }

      const url = `${LOCAL_CONFIG.urls.client}${tmp}`;

      try {
        const r = await fetch(url, {
          method: method,
          body: JSON.stringify(this.client),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });

        if (r.status === 200 || r.status === 201) {
          this.$emit('showAlert', 'Данные сохранены');
          this.$emit('closeDialog',);
          this.closeDialog();
        } else {
          this.$emit('showAlert', await r.text());
        }
      } catch (error) {
        this.$emit('showAlert', 'Ошибка при обращение к серверу');
      }

    },

  },


}
</script>

<style scoped>
.v-dialog__container {
  display: block;

}

.required label::after {
  content: "*";
}

.close-btn:hover i {
  color: #ffa900;
}

.wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.v-dialog__content--active {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>