<template>
  <div class="w-100 px-3 pt-2  d-flex flex-column ">
    <!-- панель фильтров и сортировок -->
    <div class="panel-filtrov row ">
      <div class="col-12">
        <h1>Дома и лифты</h1>

        <!-- поиск и фильтр -->
        <div class="row poisk-i-filtri">
          <div class="col-12 col-lg-3 mb-3 mb-lg-0">
            <v-text-field label="Поиск дома" @keydown.enter="getList" @blur="getList"
                          v-model="filterAndSort.address"></v-text-field>
          </div>

          <div class="col-12 col-lg-2 mb-3 mb-lg-0">
            <v-select
                label="Сортировка по:"
                v-model="filterAndSort.orderBy"
                item-text="label"
                item-value="id"
                :items="orderByList"
            ></v-select>
          </div>

          <div class="col-12 col-lg-2 mb-3 mb-lg-0">
            <v-select
                label="В архиве"
                v-model="filterAndSort.inArchive"
                item-text="label"
                item-value="id"
                :items="yesNoNullList"
            ></v-select>
          </div>

          <div class="col-12 col-lg-2 mb-3 mb-lg-0">
            <v-select
                label="Расклейщик"
                v-model="filterAndSort.placemarker"
                item-text="fio"
                item-value="id"
                :items="placemarkerList"
            ></v-select>
          </div>


          <div class="col-12 col-lg-3 text-right">
            <v-btn class="ms-auto shadow " color="orange" dark @click="houseCreatePhotoDirFtp()">
              FTP: Создать каталоги
            </v-btn>
          </div>

        </div>

        <!-- записей к показу -->
        <div class="">
          <span class="small">Отображается:  {{ houseList.length }} из {{ houseServerCount }}</span>
          <a @click="resetFilterAndSort()" class="small ms-2 d-inline-block text-warning">(Сбросить фильтры)</a>
        </div>

      </div>
    </div>

    <!-- ajax прогресс -->
    <v-progress-linear
        indeterminate
        :active="ajaxLoad"
        class="mt-3"
        color="yellow darken-2"
    ></v-progress-linear>

    <!-- список домов и лифтов -->
    <div class="tabliza-spiska-domov row">

      <div class="col-12">

        <v-simple-table
            v-if="houseList.length > 0"
            fixed-header
            height="700px"
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Адрес
              </th>
              <th class="text-left">
                Лифтов
              </th>
              <th class="text-left">
                Всего места для А4
              </th>
              <th class="text-left">
                Индекс сортировки
              </th>
              <th class="text-left">
                Расклейщик
              </th>
              <th class="text-left">
                В архиве
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in houseList"
                :key="item.address"
                @click="showDialog(item.id)"
                class="pointer-cursor"
            >
              <td>{{ item.address }}</td>
              <td>{{ item.elevatorCount }}</td>
              <td>{{ item.advertisingSpace }}</td>
              <td>{{ item.orderIndex }}</td>
              <td>{{ item.placemarkerFio }}</td>
              <td>{{ item.inArchive | isInArchive }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-alert
            v-else
            dense
            icon="mdi-information-outline"
            text
            color="orange"
        ><span>Поиск не дал результата или сервер не отвечает={ Уточните пожалуйста параметры поиска.</span>
        </v-alert>

      </div>

    </div>


    <!-- модалка -->
    <app-house-window
        ref="houseWindow"
        @closeDialog="closeDialog($event)"
        @showAlert="$emit('showAlert', $event)"
    ></app-house-window>

    <!-- кнопка добавить -->
    <div class="knopki-upravlenia-kontentom">
      <v-btn
          class="mx-2 orange btn-add"
          fab
          @click="showDialog()"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
    </div>

  </div>
</template>

<script>

import AppHouseWindow from "@/components/AppHouseWindow";
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";


export default {
  name: 'HouseView',

  components: {
    AppHouseWindow
  },

  data() {
    return {
      houseList: [],
      ajaxLoad: false,
      filterAndSort: {
        'orderBy': '-order_index',
        'address': '',
        'inArchive': false,
      },
      placemarkerList: [],
      houseServerCount: 0,
      orderByList: [
        {id: 'address', label: 'Наименования от А до Я'},
        {id: '-address', label: 'Наименования от Я до А'},
        {id: '-order_index', label: 'Согласно КП'},
      ],
      yesNoNullList: LOCAL_CONFIG.yesNoList,
    }
  },

  methods: {
    showDialog(curentDomId = null) {
      this.$refs.houseWindow.openDialog(curentDomId);
    },

    closeDialog() {
      this.getList();
    },

    resetFilterAndSort() {
      this.filterAndSort = {
        orderBy: '-order_index',
        address: '',
        inArchive: false
      };
      this.getList();
    },

    async getPlacemarkerList() {
      const url = `${LOCAL_CONFIG.urls.placemarker}`;
      const r = await fetch(url);
      this.placemarkerList = await r.json();
    },

    async getList() {
      this.ajaxLoad = true;
      const url = LOCAL_CONFIG.urls.house + `?${new URLSearchParams(this.filterAndSort)}`;
      const response = await fetch(url);
      const jsonData = await response.json();
      this.houseList = jsonData.data;
      this.houseServerCount = jsonData.count;
      this.ajaxLoad = false;
    },

    async houseCreatePhotoDirFtp() {
      try {
        const rersponse = await fetch(LOCAL_CONFIG.urls.houseCreatePhotoDirFtp);
        if (rersponse.status === 200) {
          this.$emit('showAlert', 'Список каталогов на FTP обновился');
        } else {
          this.$emit('showAlert', 'Ошибка при обращение к серверу');
        }
      } catch (e) {
        this.$emit('showAlert', 'Ошибка при обращение к серверу');
      }

    }
  },

  filters: {
    isInArchive: function (value) {
      return value ? 'В архиве' : 'Нет'
    }
  },

  async created() {
    await this.getPlacemarkerList();
    await this.getList();
    this.$emit('loadComplete',);
  },

  watch: {
    'filterAndSort.orderBy'() {
      this.getList();
    },
    'filterAndSort.inArchive'() {
      this.getList();
    },
    'filterAndSort.placemarker'() {
      this.getList();
    },
  },
}
</script>


<style lang="scss" scoped>
.btn-add {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #fff !important;
}
</style>
