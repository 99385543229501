<template>
  <div class="position-relative" :class="{'wrap':dialogShow}">
    <v-dialog
        :value="dialogShow"
        persistent
        scrollable
        max-width="1200px"
    >
      <v-card>
        <v-card-title class="text-center bg-light ">
          <div class="h3 " v-if="advertisingProgram.id">Редактирование РП для «{{
              advertisingProgram.clientName
            }}»
          </div>
          <div class="h3 " v-else-if="advertisingProgram === ''">Список РП</div>
          <div class="h3 " v-else>Новая РП</div>

          <div class="ms-auto">
            <button class="close-btn" @click="closeDialog">
              <v-icon>
                mdi-close-circle
              </v-icon>
            </button>
          </div>
        </v-card-title>

        <v-card-text>
          <v-tabs
              v-model="tab"
              background-color="transparent"
              color="#ffa900"
              grow
          >

            <v-tab>
              Шаг 1. Список РП
            </v-tab>

            <v-tab :disabled="advertisingProgram === ''">
              Шаг 2. Клиент
            </v-tab>

            <v-tab :disabled="advertisingProgram === ''">
              Шаг 3. Лифты
            </v-tab>

          </v-tabs>

          <v-tabs-items v-model="tab">
            <!--  Шаг 1 список РП -->
            <v-tab-item>
              <v-card
                  color="basil"
                  flat
                  class="py-3 "
              >
                <!--фильтры-->
                <div class="row">

                  <div class="col-3">
                    <v-text-field label="Поиск по клиенту" @keydown.enter="getAdvertisingProgramList"
                                  @blur="getAdvertisingProgramList"
                                  v-model="filterAndSortRp.clientName"></v-text-field>
                  </div>

                  <div class="col-3">
                    <v-select
                        label="Тип клиента"
                        :items="clientTypeList"
                        item-text="label"
                        item-value="id"
                        v-model="filterAndSortRp.clientType"
                    ></v-select>
                  </div>

                  <div class="col-2">
                    <v-select
                        label="Сортировка по:"
                        v-model="filterAndSortRp.orderBy"
                        item-text="label"
                        item-value="id"
                        :items="orderByListRp"
                    ></v-select>
                  </div>

                  <div class="col-2">
                    <v-select
                        label="В архиве"
                        :items="yesNoList"
                        item-text="label"
                        item-value="id"
                        v-model="filterAndSortRp.inArchive"
                    ></v-select>
                  </div>

                  <div class="col-2">
                    <v-select
                        label="Итоговая"
                        :items="yesNoNullList"
                        item-text="label"
                        item-value="id"
                        v-model="filterAndSortRp.isComplete"
                    ></v-select>
                  </div>

                </div>

                <div class="">

                  <div class="">
                    <span class="small">Отображается:  {{
                        advertisingProgramList.length
                      }} РП  из {{ advertisingProgramCount }} РП</span>
                    <a href="#" @click="resetAdvertisingProgram()" class="small ms-2 d-inline-block text-warning">(Сбростиь
                      фильтры)</a>
                  </div>


                  <v-progress-linear
                      indeterminate
                      :active="ajaxLoadAdvertisingProgram"
                      class="mt-3"
                      color="yellow darken-2"
                  ></v-progress-linear>
                </div>
                <template v-if="ajaxLoadAdvertisingProgram === false">
                  <v-simple-table
                      fixed-header
                      height="300px"
                      v-if="advertisingProgramList.length > 0"
                  >
                    <template v-slot:default>
                      <thead>
                      <tr>

                        <th class="text-left">
                          Клиент
                        </th>
                        <th class="text-left">
                          Тип клиента
                        </th>
                        <th class="text-left">
                          Кол-во домов
                        </th>
                        <th class="text-left">
                          Кол-во лифтов
                        </th>
                        <th class="text-left">
                          Бюджет
                        </th>
                        <th class="text-left">
                          Формат
                        </th>
                        <th class="text-left">
                          Желаемое положение
                        </th>

                        <th class="text-left" width="180">
                          Архив
                        </th>

                        <th class="text-left" width="180">
                          Итоговая?
                        </th>


                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="item in advertisingProgramList"
                          :key="item.id"
                          class="pointer-cursor"
                          @click="getAdvertisingProgramDetail(item.id)"
                      >

                        <td :class="{
                      // 'text-primary':item.clientTypeId === 1,
                      'text-info':item.clientTypeId === 2,
                      'text-success':item.clientTypeId === 3,
                      'text-warning':item.clientTypeId === 4,
                    }">{{ item.clientName }} {{ item.note }}
                        </td>
                        <td :class="{
                           'text-info':item.clientTypeId === 2,
                          'text-success':item.clientTypeId === 3,
                          'text-warning':item.clientTypeId === 4,
                        }">{{ item.clientTypeDisplay }}
                        </td>
                        <td>{{ item.houseCount }}</td>
                        <td>{{ item.elevatorCount }}</td>
                        <td>{{ item.budget }}</td>
                        <td>{{ item.paperSizeDisplay }}</td>
                        <td>{{ item.elevatorPosition }}</td>
                        <td class="text-left ">
                          <v-btn
                              color="#3599dc"
                              class="p-0"
                              text
                              v-if="!item.inArchive"
                              @click.stop="archiveAdvertisingProgram(item.id)"
                          >
                            В архив
                          </v-btn>

                          <v-btn
                              color="#DC3545"
                              text
                              v-else
                              @click.stop="archiveAdvertisingProgram(item.id)"
                          >
                            Вернуть
                          </v-btn>

                        </td>
                        <td class="text-center">
                          <v-switch color="orange" v-model="item.isComplete"
                                    @click.stop="changeIsComplete(item.id, item.isComplete)"/>
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-alert v-else
                           dense
                           icon="mdi-information-outline"
                           text
                           color="orange"
                  ><span class=" mt-3 text-dark">Поиск не дал результата или
                    РП за
                    этот период нет ={</span></v-alert>
                </template>

                <v-btn
                    class="mx-2 orange btn-add"
                    fab
                    dark
                    @click="createAdvertisingProgram()"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-card>

            </v-tab-item>


            <!-- Шаг 2 Клиент -->
            <v-tab-item
            >
              <v-card
                  color="basil"
                  flat
                  class="py-3 "
              >

                <div class="row">

                  <div class="col-12">

<!--                    <v-alert-->
<!--                        v-if="checkClientInPeriod()"-->
<!--                        dense-->
<!--                        icon="mdi-information-outline"-->
<!--                        text-->
<!--                        type="warning"-->
<!--                    >У этого клиента уже есть РП за данный период-->
<!--                    </v-alert>-->
                  </div>

                  <div class="col-6">
                    <v-autocomplete
                        label="Клиент*"
                        v-model="advertisingProgram.client"
                        item-text="name"
                        item-value="id"
                        :items="clientList"
                    />

                  </div>

                  <div class="col-6">
                    <v-text-field label="Примечание" v-model="advertisingProgram.note"></v-text-field>
                  </div>

                  <div class="col-6">
                    <v-select
                        label="Формат*"
                        v-model="advertisingProgram.paperSize"
                        item-text="name"
                        item-value="id"
                        :items="paperSizeList"
                    ></v-select>
                  </div>

                  <div class="col-6">
                    <v-select
                        label="Статус РП*"
                        v-model="advertisingProgram.status"
                        item-text="label"
                        item-value="id"
                        :items="statusAdvertisingProgramList"
                    ></v-select>
                  </div>

                  <div class="col-6">
                    <v-text-field label="Бюджет"
                                  v-model="advertisingProgram.budget"></v-text-field>
                  </div>

                  <div class="col-6">
                    <v-autocomplete
                        label="Желаемое расположение"
                        v-model="advertisingProgram.elevatorPosition"
                        item-text="name"
                        item-value="id"
                        :items="elevatorPositionList"
                    />
                  </div>

                  <div class="col-12 text-right">
                    <v-btn
                        v-if="advertisingProgram.id"
                        color="orange"
                        outlined
                        class="mr-4"
                        dark
                        @click="advertisingProgramSave(true)"
                    >
                      <v-icon></v-icon>
                      Сохранить и обновить позиции
                    </v-btn>

                    <v-btn
                        color="orange"
                        dark
                        @click="advertisingProgramSave()"
                    >
                      <v-icon>mdi mdi-content-save</v-icon>
                      Сохранить
                    </v-btn>
                  </div>

                </div>
              </v-card>
            </v-tab-item>


            <!-- Шаг 3 Лифты -->
            <v-tab-item
            >
              <v-card
                  color="basil"
                  flat
                  class="py-3 "
              >

                <div class="row">

                  <div class="col-3 pb-0">
                    <v-text-field label="Поиск дома"
                                  v-model="filterAndSortHome.address">

                      <template v-slot:append-outer>
                        <v-icon @click="filterAndSortHome.address=``">mdi-close</v-icon>
                      </template>
                    </v-text-field>
                  </div>


                  <div class="col-3 pb-0">
                    <v-select
                        label="Сортировка по:"
                        v-model="filterAndSortHome.orderBy"
                        item-text="label"
                        item-value="id"
                        :items="orderByListClient"
                    ></v-select>
                  </div>


                   <div class="col-3 pb-0">
                    <v-select
                        label="Скрывать дома где есть клиент"
                        v-model="filterAndSortHome.dontCurentClient"
                        item-text="label"
                        item-value="id"
                        :items="[
                            {label:'Нет', id:0},
                            {label:'Да', id:1},
                        ]"
                    ></v-select>
                  </div>

                  <div class="col-3 pb-0">
                    <v-select
                        label="Скрывать занятые"
                        v-model="filterAndSortHome.homeShowBusy"
                        item-text="label"
                        item-value="id"
                        :items="homeShowBusyList"
                    ></v-select>
                  </div>



                  <!-- итоги -->
                  <div class="col-12 my-1 py-0">
                    <span v-if="advertisingProgram.houseIdList">Выбрано {{ advertisingProgram.houseIdList.length }} домов и {{
                        selectElevatorCount
                      }} лифтов </span>
                  </div>


                  <div class="col-12">
                    <div v-if="homeListFiltred.length > 0">

                      <v-simple-table
                          fixed-header
                          height="300px"

                      >
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th class="text-left">

                            </th>
                            <th class="text-left">
                              Адрес
                            </th>
                            <th class="text-left">
                              Свобоно мест
                            </th>
                            <th class="text-left">
                              Лифтов в доме
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                              v-for="item in homeListFiltred"
                              :key="item.address"
                          >
                            <td>
                              <v-switch
                                  class="m-0 p-0"
                                  color="orange"
                                  hide-details
                                  :value="item.id"
                                  v-model="advertisingProgram.houseIdList"
                              />
                            </td>
                            <td>{{ item.address }}</td>
                            <td>{{ item.freeSpace }}</td>
                            <td>{{ item.elevatorCount }}</td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <div class="row">
                        <div class="col-6">
                          <v-btn
                              class="mr-3"
                              color="orange"
                              dark
                              outlined
                              text
                              @click="checkSelectHome()"
                          >
                            <v-icon>mdi-checkbox-marked-outline</v-icon>
                            Отметить выбранные
                          </v-btn>

                          <v-btn
                              color="orange"
                              dark
                              outlined
                              text
                              @click="uncheckSelectHome()"
                          >
                            <v-icon>mdi-checkbox-blank-outline</v-icon>
                            Снять выбранные
                          </v-btn>
                        </div>
                        <div class="col-6 text-right">
                          <v-btn
                              v-if="advertisingProgram.id"
                              color="orange"
                              class="mr-4"
                              outlined
                              dark
                              @click="advertisingProgramSave(true)"
                          >
                            <v-icon></v-icon>
                            Сохранить и обновить позиции
                          </v-btn>

                          <v-btn
                              color="orange"
                              dark
                              @click="advertisingProgramSave()"
                          >
                            <v-icon>mdi mdi-content-save</v-icon>
                            Сохранить
                          </v-btn>
                        </div>
                      </div>
                    </div>

                    <div v-else class="text-center "><b>Дом не найдён. Уточните поиск...</b></div>
                  </div>

                </div>
              </v-card>
            </v-tab-item>

          </v-tabs-items>

        </v-card-text>


      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "AppAdvertisingProgramWindow",
  props: ['period'],

  data() {
    return {
      //РП
      advertisingProgramList: [],
      ajaxLoadAdvertisingProgram: false,
      advertisingProgram: {
        client: 0,
        houseIdList:[]
      },
      filterAndSortRp: {
        clientName: '',
        clientType: '',
        paperSize: '',
        inArchive: false,
        isComplete: '',
        orderBy: 'client__name'
      },
      advertisingProgramCount: '',
      clientTypeList: LOCAL_CONFIG.clientTypeList,
      orderByListRp: [
        {id: 'client__name', label: 'Наименования от А до Я'},
        {id: '-client__name', label: 'Наименования от Я до А'},
      ],

      // клиент
      paperSizeList: [],
      statusAdvertisingProgramList: LOCAL_CONFIG.statusAdvertisingProgramList,
      clientList: [],

      // дома
      homeList: [],
      allHouseList: [],
      ajaxLoadHome: false,
      filterAndSortHome: {
        orderBy: '-order_index',
        address: '',
        dontCurentClient: 0,
        homeShowBusy: 'free',
      },

      orderByListClient: [
        {id: 'address', label: 'Наименования от А до Я'},
        {id: '-address', label: 'Наименования от Я до А'},
        {id: '-order_index', label: 'Согласно КП'},
      ],

      homeShowBusyList: [
        {id: 'all', label: 'Все'},
        {id: 'free', label: 'Только свободные'},
        {id: 'bysy', label: 'Только занятые'},
        {id: 'select', label: 'Только выбранные'},
      ],


      // прочее
      yesNoNullList: LOCAL_CONFIG.yesNoNullList,
      yesNoList: LOCAL_CONFIG.yesNoList,
      tab: null,
      dialogShow: false


    }
  },

  computed: {
    homeListFiltred: function () {
      const result = [];
      this.homeList.forEach(item => {
        const isBysy = this.filterAndSortHome.homeShowBusy === 'bysy' && item.freeSpace <= 0;
        const isFree = this.filterAndSortHome.homeShowBusy === 'free' && item.freeSpace > 0;
        const isAll = this.filterAndSortHome.homeShowBusy === 'all';
        const isSelect = this.filterAndSortHome.homeShowBusy === 'select' && this.advertisingProgram.houseIdList.indexOf(item.id) !== -1;

        if (isBysy || isFree || isSelect || isAll) {
          result.push(item);
        }
      })
      return result;
    },

    elevatorPositionList: function () {
      //todo тянуть из макета. ХЗ пока как, так как у домов разные макеты
      const magicVaribalMaket = 8;
      const finder = this.paperSizeList.find(item => item.id === this.advertisingProgram.paperSize)

      if (finder) {
        const result = [];
        for (let i = 1; i <= (magicVaribalMaket / finder.ratioToA4); i++) {
          result.push({name: i, 'id': i});
        }
        return [
          {name: 'Любое', 'id': null},
          ...result
        ]
      } else {
        return [{name: 'Любое', 'id': null}]
      }
    },

    selectElevatorCount: function () {
      let sum = 0;
      for (const house_id of this.advertisingProgram.houseIdList) {
        const finder = this.allHouseList.find(item => item.id === house_id)
        if (finder) {
          sum += finder.elevatorCount
        }
      }
      return sum
    }
  },

  methods: {
    //модалка

    closeDialog: function () {
      this.dialogShow = false;
      this.homeList = [];
      this.resetAdvertisingProgram();
      this.tab = 0;
      this.filterAndSortHome = {
        orderBy: '-order_index',
        address: '',
        dontCurentClient: 0,
        homeShowBusy: 'free',
      };
      this.$emit('closeDialog', {});
    },

    async openDialog(id) {
      this.resetAdvertisingProgram();
      // this.getAdvertisingProgramList();
      this.getClientList();

      this.getHomeList(true);


      if (id) {
        await this.getAdvertisingProgramDetail(id)
      } else {
        this.tab = 0;

      }
      this.dialogShow = true;
    },

    //РП
    resetAdvertisingProgram: function () {
      this.filterAndSortRp = {
        clientName: '',
        clientType: '',
        paperSize: '',
        inArchive: false,
        isComplete: '',
        orderBy: 'client__name'
      }
      this.advertisingProgram = {
        client: 0,
        houseIdList:[]
      };
      this.advertisingProgramCount = 0;
      this.getAdvertisingProgramList();
    },

    createAdvertisingProgram: function () {
      this.advertisingProgram = {
        houseIdList: [],
        client: '',
        budget: null,
        status: 1,
        paperSize: 1,
        elevatorPosition: null,
      };
      this.tab = 1;
      this.getHomeList();
      this.filterAndSortHome.homeShowBusy = 'free';
    },

    async getAdvertisingProgramList() {
      this.ajaxLoadAdvertisingProgram = true;
      const url = `${LOCAL_CONFIG.urls.advertisingProgram}${this.period}/?${new URLSearchParams(this.filterAndSortRp)}`;
      const response = await fetch(url, {method: 'get'});
      const resultJson = await response.json();
      this.advertisingProgramList = resultJson['data'];
      this.advertisingProgramCount = resultJson['advertisingProgramCount'];
      this.ajaxLoadAdvertisingProgram = false;
    },

    async changeIsComplete(id, isComplete) {
      const url = `${LOCAL_CONFIG.urls.advertisingProgramChangeComplete}`;
      const r = await fetch(url,
          {
            method: 'post',
            body: JSON.stringify({
              'id':id,
              'isComplete':isComplete
            }),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CookieHelper.getCookie('csrftoken')
            },
          }
      );
      if (r.status === 200 || r.status === 201) {
        this.$emit('showAlert', 'Данные сохранены');
      } else {
        this.$emit('showAlert', await r.text());
      }
    },

    async getAdvertisingProgramDetail(id) {

      const url = `${LOCAL_CONFIG.urls.advertisingProgram}${this.period}/${id}/`;
      try {
        const response = await fetch(url, {method: 'get'});
        const resultJson = await response.json();
        this.advertisingProgram = resultJson;
        this.advertisingProgram.oldClientId = resultJson.client;
        this.tab = 1;
        this.filterAndSortHome.homeShowBusy = 'select'
        this.getHomeList();
      } catch (e) {
        this.$emit('showAlert', 'Ошибка при обращение к серверу')
      }

    },

    async archiveAdvertisingProgram(id) {

      const url = `${LOCAL_CONFIG.urls.advertisingProgram}${this.period}/${id}/`;
      try {
        const r = await fetch(url, {
          method: 'delete',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        if (r.status === 200 || r.status === 201) {
          this.$emit('showAlert', 'Данные сохранены');
          this.getAdvertisingProgramList();
        } else {
          this.$emit('showAlert', await r.text());
        }
      } catch (error) {
        this.$emit('showAlert', 'Ошибка при обращение к серверу');
      }

    },

    validateSaveData() {
      const is1 = this.advertisingProgram.client !== undefined && this.advertisingProgram.client !== "";
      const is2 = this.advertisingProgram.paperSize !== undefined && this.advertisingProgram.paperSize !== "";
      const is3 = this.advertisingProgram.status !== undefined && this.advertisingProgram.status !== "";
      return is1 && is2 && is3;
    },

    async advertisingProgramSave(isUpdatePosition = false) {
      if (!this.validateSaveData()) {
        this.$emit('showAlert', 'Не все поля  со звездочкой * заполнены');
        return false;
      }

      if (this.advertisingProgram.houseIdList.length === 0) {
        this.$emit('showAlert', 'Не выбраны дома');
        return false;
      }


      const id = this.advertisingProgram.id > 0 ? `${this.advertisingProgram.id}/` : '';
      const method = this.advertisingProgram.id > 0 ? `PUT` : 'POST';
      const url = `${LOCAL_CONFIG.urls.advertisingProgram}${this.period}/${id}`;
      try {
        const body = this.advertisingProgram;
        body.period = this.period;
        body.isUpdatePosition = isUpdatePosition;
        const r = await fetch(url, {
          method: method,
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });

        if (r.status > 201) {
          this.$emit('showAlert', await r.text());
        } else {
          this.$emit('showAlert', 'Данные сохранены')
          this.closeDialog()
        }
      } catch (error) {
        this.$emit('showAlert', 'Ошибка при обращение к серверу')
      }

    },

    //КЛИЕНТ
    async getFormatList() {
      const url = `${LOCAL_CONFIG.urls.paperSize}`;
      const response = await fetch(url, {method: 'get'});
      this.paperSizeList = await response.json();
    },

    async getClientList() {
      const url = `${LOCAL_CONFIG.urls.clientAutocomplite}${this.period}/`;
      const response = await fetch(url, {method: 'get'});
      this.clientList = await response.json();
    },

    checkClientInPeriod: function () {
      // если мы не меняли клиента после выбора, то и алерт не показываем
      if (this.advertisingProgram.client === this.advertisingProgram.oldClientId) {
        return false
      }
      const finder = this.clientList.find(i => i.id === this.advertisingProgram.client);
      if (finder) {
        // return finder.klient_est_v_periode;
        return true
      }
      return false
    },


    //ДОМА
    async getHomeList(updateAllHouseList = false) {
      this.ajaxLoadHome = true;
      const url = `${LOCAL_CONFIG.urls.freeSpaceElevator}${this.period}/?` + new URLSearchParams(this.filterAndSortHome) + `&client=${this.advertisingProgram.client}`;
      const response = await fetch(url, {method: 'get'});
      const data = await response.json();
      this.homeList = data;
      this.ajaxLoadHome = false;
      if (updateAllHouseList) {
        this.allHouseList = data;
      }
    },

    uncheckSelectHome: function () {
      //снять выбранные дома
      for (const item of this.homeListFiltred) {
        this.advertisingProgram.houseIdList = this.advertisingProgram.houseIdList.filter(i => i !== item.id);
      }
    },

    checkSelectHome: function () {
      for (const item of this.homeListFiltred) {
        if (this.advertisingProgram.houseIdList.indexOf(item.id) === -1) {
          this.advertisingProgram.houseIdList.push(item.id)
        }
      }

    },


  },


  async created() {
    this.getFormatList();
  },

  watch: {

    //РП
    'filterAndSortRp.clientType':
        function () {
          this.getAdvertisingProgramList();
        },

    'filterAndSortRp.paperSize':
        function () {
          this.getAdvertisingProgramList();
        },
    'filterAndSortRp.inArchive':
        function () {
          this.getAdvertisingProgramList();
        },

    'filterAndSortRp.isComplete':
        function () {
          this.getAdvertisingProgramList();
        },
    'filterAndSortRp.orderBy':

        function () {
          this.getAdvertisingProgramList();
        },


    //ДОМА
    'filterAndSortHome.orderBy':

        function () {
          this.getHomeList();
        },

    'filterAndSortHome.address':

        function () {
          this.getHomeList();
        },

    'filterAndSortHome.homeShowBusy':

        function () {
          this.getHomeList();
        },

     'filterAndSortHome.dontCurentClient':
        function () {
          this.getHomeList();
        },

  }

}
</script>

<style scoped>
.v-dialog__container {
  display: block;

}

.required label::after {
  content: "*";
}

.close-btn:hover i {
  color: #ffa900;
}

.wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.v-dialog__content--active {
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-add {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff !important;
}
</style>