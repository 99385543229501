<template>
  <v-card class="d-flex flex-column flex-shrink-0  text-white border-radius-0" dark>


    <!-- логотип -->
    <div class="logo-place">
      <v-list-item class="px-2">
        <v-list-item-avatar class="me-2">
          <img src="../assets/logo2.png">
        </v-list-item-avatar>
        <v-list-item-title><b>КЕНГУРУ CRM</b></v-list-item-title>
      </v-list-item>
    </div>


    <!-- навигация -->
    <v-list dense class="nav-place w-100 ">
      <v-list-item-group>

        <router-link class="nav-links" :to="item.name" v-for="item in items" :key="item.name">
          <v-list-item v-if="item.onlyAdmin === false || user.isAdmin "
          >

            <v-list-item-icon class="me-3">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </router-link>

      </v-list-item-group>
    </v-list>

    <!-- логин/разлогин -->
    <div class="user-place mt-auto w-100">
      <v-list dense>

        <template v-if="user.userName">
          <v-list-item
              link
              @click="logout"
          >
            <v-list-item-icon class="me-3 ">
              <v-avatar
                  color="#fff"
                  size="28"
              >
                <v-icon color="orange">mdi-account-multiple-outline</v-icon>
              </v-avatar>
            </v-list-item-icon>

            <v-list-item-content class="mt-1">
              <v-list-item-title> Выйти ({{ user.userName }})</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <router-link v-else class="nav-links" to="login">
          <v-list-item
              link
          >
            <v-list-item-icon class="me-3 ">
              <v-avatar
                  color="#fff"
                  size="28"
              >
                <v-icon color="orange">mdi-account-multiple-outline</v-icon>
              </v-avatar>
            </v-list-item-icon>

            <v-list-item-content class="mt-1">
              <v-list-item-title> Войти</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>


      </v-list>
    </div>

    <!-- о разработчике -->
    <div class="glad-place">
      <div class="text-white px-3 mt-3 small">
        <b>Разработка <a class="text-danger text-decoration-none d-inline" href="https://kirillgladkikh.ru/">Гладких
          К.А.</a></b></div>
    </div>


  </v-card>
</template>

<script>

import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "AppNavbar",
  props: ['user'],

  methods: {
    logout() {
      CookieHelper.setCookie('sessionid', "", {
        'max-age': -1
      })
      location.reload();
    }
  },

  data() {
    return {
      items: [
        {title: 'Рекламные программы', icon: 'mdi-clipboard-list', 'name': 'advertising_program', onlyAdmin: true},
        {title: 'Клиенты', icon: 'mdi-account-group-outline', 'name': 'client', onlyAdmin: true},
        {title: 'Дома и лифты', icon: 'mdi-home-city', 'name': 'house', onlyAdmin: true},
        {title: 'Свободные площадки', icon: 'mdi-home-analytics', 'name': 'freeElevatorsView', onlyAdmin: false},
        {title: 'Супермаркеты', icon: 'mdi-shopping', 'name': 'market', onlyAdmin: true},
      ],
    }
  },
}
</script>

<style lang="scss" scoped>


.router-link-exact-active div {
  //color: #ffa900 !important;

}

.router-link-exact-active, .router-link-exact-active:hover, {
  background-color: rgba(255, 169, 0, 0.53) !important;
  color: #fff !important;
}

.v-list-item--link {

}


a {
  text-decoration: none;
  text-outline: none;
  display: flex;
  width: 100%;

  &:hover {
    color: #ffa900 !important;
  }
}


.v-card {
  width: 225px;
  height: 100vh;
}


.v-list-item a, .v-list-item--active a {
  &:hover {
    color: #FFA900FF !important;
  }

  color: #777777 !important;

}


.v-list-item .icon {
  color: #fff !important;
}

.v-item--active a {
  color: #FFA900FF !important;
}
</style>