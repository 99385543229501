<template>
  <div class="w-100 px-3 pt-2  d-flex flex-column ">
    <!-- панель фильтров и сортировок -->
    <div class="panel-filtrov row ">
      <div class="col-12">
        <h1>Супермаркеты</h1>

        <!-- поиск и фильтр -->
        <div class="row poisk-i-filtri">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <v-text-field label="Поиск супермаркета" @keydown.enter="getList" @blur="getList"
                          v-model="filterAndSort.name"></v-text-field>
          </div>

          <div class="col-12 col-lg-4 mb-3 mb-lg-0">
            <v-select
                label="Отображать только проблемные"
                v-model="filterAndSort.onlyError"
                item-text="label"
                @change="getList"
                item-value="id"
                :items="yesNoList"
            ></v-select>
          </div>



          <div class="col-12 col-lg-2 text-right">
            <v-btn class="ms-auto shadow w-100"  color="orange" dark :href="marketSimpleViewUrl" target="_blank">
              Упрощенный вид
            </v-btn>
          </div>


        </div>

        <!-- записей к показу -->
        <div class="">
          <span class="small">Отображается:  {{ marketList.length }} из {{ marketServerCount }}</span>
          <a @click="resetFilterAndSort()" class="small ms-2 d-inline-block text-warning">(Сбросить фильтры)</a>
        </div>

      </div>
    </div>

    <!-- ajax прогресс -->
    <v-progress-linear
        indeterminate
        :active="ajaxLoad"
        class="mt-3"
        color="yellow darken-2"
    ></v-progress-linear>

    <!-- список маркетов -->
    <div class="row">

      <div class="col-12">

        <v-simple-table
            v-if="marketList.length > 0"
            fixed-header
            height="700px"
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                ID
              </th>

              <th class="text-left">
                Название
              </th>
              <th class="text-left">
                Время работы
              </th>
              <th class="text-left">
                Ответственный
              </th>
              <th class="text-left">
                Статус
              </th>
              <th class="text-left">
                Смена статуса
              </th>
              <th class="text-left">
                Синхронизация
              </th>
               <th class="text-left">
                Интервал проверки (сек)
              </th>
               <th class="text-left">
                Кол-во повторных интервалов
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in marketList"
                :key="item.id"
                :class="{
                  'green-1':item.connectStatus === 3,
                  'red-1':item.connectStatus === 4 ,
                  'blue-1':item.connectStatus === 5,
                }"
            >
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.timeWork }}</td>
              <td>{{ item.owner }}</td>
              <td>{{ item.statusText }}</td>
              <td>{{ item.dateChangeStatus }}</td>
              <td>{{ item.dateUpdate }}</td>
              <td>{{ item.syncTimeSec }}</td>
              <td>{{ item.dontAudioCountSend }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-alert
            v-else
            dense
            icon="mdi-information-outline"
            text
            color="orange"
        ><span>Поиск не дал результата или сервер не отвечает={ Уточните пожалуйста параметры поиска.</span>
        </v-alert>

      </div>

    </div>


  </div>
</template>

<script>

import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";


export default {
  name: 'MarketView',

  components: {},

  data() {
    return {
      marketList: [],
      marketServerCount: 0,
      ajaxLoad: false,
      marketSimpleViewUrl: LOCAL_CONFIG.urls.marketSimpleView,
      filterAndSort: {
        'onlyError': false,
        'name': '',
      },
      yesNoList: LOCAL_CONFIG.yesNoList,
    }
  },


  methods: {

    async getList(showAjaxLoad = true) {
      this.ajaxLoad = showAjaxLoad;
      const url = LOCAL_CONFIG.urls.market + `?${new URLSearchParams(this.filterAndSort)}`;
      const response = await fetch(url);
      const jsonData = await response.json();
      this.marketList = jsonData.data;
      this.marketServerCount = jsonData.count;
      this.ajaxLoad = false;
    },

    // async update(id) {
    //   this.ajaxLoad = true;
    //   const url = LOCAL_CONFIG.urls.marketUpdate + `${id}`;
    //   const response = await fetch(url);
    //   if (response.status !== 200) {
    //     this.$emit('showAlert', 'Ошибка обновления. Подробности в консоли');
    //   }
    //   this.ajaxLoad = false;
    //   await this.getList()
    // },
    //
    // async updateAll() {
    //   this.ajaxLoad = true;
    //   const url = LOCAL_CONFIG.urls.marketUpdateAll;
    //   const response = await fetch(url);
    //   if (response.status !== 200) {
    //     this.$emit('showAlert', 'Ошибка обновления. Подробности в консоли');
    //   }else {
    //     this.$emit('showAlert', 'Готово');
    //   }
    //   this.ajaxLoad = false;
    //   await this.getList()
    // },

    resetFilterAndSort() {
      this.filterAndSort = {
        onlyError: false,
        name: '',
      };
      this.getList();
    },
  },


  async created() {
    await this.getList();
    this.$emit('loadComplete',);

    setInterval( async() =>{
      await this.getList(false)
    }, 5000)
  },


}
</script>


<style lang="scss" scoped>
.green-1 {
  color: green;
  background-color: rgba(0, 128, 0, 0.1) !important;
}

.red-1 {
  color: red;
  background-color: rgba(255, 0, 0, 0.1) !important;
}

.blue-1 {
  color: blue;
  background-color: rgba(0, 0, 255, 0.1) !important;
}
</style>
