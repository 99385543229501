import Vue from 'vue'
import VueRouter from 'vue-router'
import ClientiView from "@/views/ClientiView";
import HouseView from "@/views/HouseView";
import AdvertisingProgramView from "@/views/AdvertisingProgramView";
import FreeElevatorsView from "@/views/FreeElevatorsView";
import LoginView from "@/views/LoginView";
import MarketView from "@/views/MarketView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'freeElevatorsView',
        component: FreeElevatorsView
    },
    {
        path: '/client',
        name: 'client',
        component: ClientiView
    },
    {
        path: '/house',
        name: 'house',
        component: HouseView
    },
    {
        path: '/market',
        name: 'market',
        component: MarketView
    },
    {
        path: '/advertising_program',
        name: 'advertising_program',
        component: AdvertisingProgramView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },

     {
        path: '*',
        name: 'page404',
        component: FreeElevatorsView
    },

]

const router = new VueRouter({
    routes
})

export default router
